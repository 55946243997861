import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../redux";
import * as actions from "../../redux/notifications/actions";
import * as appActions from "../../redux/app/actions";
import classNames from "classnames";
import { Button, Toast, ToastContainer } from "react-bootstrap";
import { ExclamationTriangle } from "react-bootstrap-icons";
import { Notification, NotificationType } from "../../redux/notifications/types";
import { useTranslate } from "../../hooks/app";

interface ConnectedProps {
  onClose: () => void;
  children: Notification;
}

export const getIcon = (type: NotificationType) => {
  if (type === NotificationType.ERROR) {
    return <ExclamationTriangle className="bi me-2" />;
  }
};
export const getVariant = (type: NotificationType) => {
  if (type === NotificationType.ERROR) {
    return "danger";
  } else if (type === NotificationType.SUCCESS) {
    return "success";
  }
  return undefined;
};

const NotificationElement = ({ children: { message, type, autoclose }, onClose }: ConnectedProps) => {
  const t = useTranslate();
  return (
    <Toast show={true} autohide={autoclose} delay={6000} onClose={onClose} bg={getVariant(type)}>
      <Toast.Header closeButton>
        {getIcon(type)}
        <strong className="me-auto">{t("notification.title." + type)}</strong>
      </Toast.Header>
      <Toast.Body className={classNames(type)}>
        <span>{message}</span>
      </Toast.Body>
    </Toast>
  );
};

const UpdateElement = ({ execute }: { execute: () => void }) => {
  const t = useTranslate();
  return (
    <Toast show={true} bg={"info"}>
      <Toast.Header closeButton={false}>
        <strong className="me-auto">{t("notification.title.update")}</strong>
      </Toast.Header>
      <Toast.Body>
        <Button variant="primary" onClick={execute}>
          Jetzt aktualisieren
        </Button>
      </Toast.Body>
    </Toast>
  );
};

interface ConnectedContainerProps {
  list: Notification[];
  update: boolean;
  removeNotification: typeof actions.removeNotification;
  executeUpdate: typeof appActions.executeUpdate;
}
const Notifications = ({ list, removeNotification, update, executeUpdate }: ConnectedContainerProps) => (
  <ToastContainer /*position={"bottom-end"}*/ className={"p-3 position-fixed bottom-0 end-0"}>
    {list.map((n, i) => (
      <NotificationElement key={i} onClose={() => removeNotification(n)}>
        {n}
      </NotificationElement>
    ))}
    {update && <UpdateElement execute={executeUpdate} />}
  </ToastContainer>
);

export default connect(
  (state: RootState) => ({
    list: state.notifications.list,
    update: state.app.updateAvailable,
  }),
  {
    removeNotification: actions.removeNotification,
    executeUpdate: appActions.executeUpdate,
  }
)(Notifications);
