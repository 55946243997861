import { ItemData } from "./models/ItemInstance";

export const ItemDataSerializer = {
  empty(data?: ItemData) {
    if (!data) {
      return true;
    }
    let empty = true;
    Object.keys(data).forEach((k) => {
      if (k !== "id" && k !== "_id" && data[k as keyof ItemData] !== undefined) {
        empty = false;
      }
    });
    return empty;
  },
  stringify: (data: ItemData) => {
    const ordered = [data.color !== undefined ? String.fromCharCode(data.color) : ""];

    return ordered.join("|");
  },
  parse: (data: string) => {
    const [rawColor] = data.split("|");

    const result: ItemData = {};
    if (rawColor) {
      result.color = rawColor.charCodeAt(0);
    }
    return result;
  },
};
