import * as React from "react";
import { useFormInput } from "../../hooks/form";
import { Button, Card, Col, Form, FormControl, Row } from "react-bootstrap";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router";
import { useApp, useTranslate } from "../../hooks/app";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form as FormFields } from "react-bootstrap-formik";
import { FileNotFound } from "./404";
import { IoDocument } from "@jas/rnd-game-types/lib";
import { Account } from "@jas/rnd-game-types/lib/models/Account";
import { LoginForm } from "./login";

const PasswordResetRequested = () => (
  <div>
    <p>
      Wir haben deine Passwort-vergessen-Anforderung erhalten. Wenn unter dieser E-Mail-Adresse ein Account existiert, solltest du nun eine E-Mail mit weiteren
      Anweisungen erhalten haben. Bitte überprüfen auch deinen Spam-Ordner.
    </p>
  </div>
);

const PasswordRequestForm = () => {
  const username = useFormInput("");
  const navigate = useNavigate();
  const app = useApp();
  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        (await app.api.post("/password-reset", {
          username: username.value,
        })) as { data: { success: boolean } };
        navigate("/password-reset", { state: { success: true } });
      }}
    >
      <Form.Group className="mb-3">
        <Form.Label>E-Mail-Adresse</Form.Label>
        <FormControl type="email" id="form-group-input-email" placeholder="email@example.com" {...username} />
      </Form.Group>
      <Button className="d-block" type="submit">
        Passwort zurücksetzen
      </Button>
    </Form>
  );
};

const PasswordChangeSchema = Yup.object().shape({
  password: Yup.string().required().min(8),
  passwordRepeat: Yup.string().oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
});
const PasswordChangeForm = () => {
  const app = useApp();
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const t = useTranslate();

  return (
    <Formik
      initialValues={{ password: "", passwordRepeat: "" }}
      validationSchema={PasswordChangeSchema}
      onSubmit={async (values, formikHelpers) => {
        const { data } = (await app.api.post("/password-reset/changepw", {
          token,
          password: values.password,
        })) as { data: { success: boolean; account?: IoDocument<Account>; errors?: { [key: string]: string } } };

        if (data.success) {
          navigate("../success", { state: { username: data.account?.email } });
        } else {
          for (const [field, error] of Object.entries(data.errors || [])) {
            formikHelpers.setFieldError(field, t(error));
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Passwort</Form.Label>
            <FormFields.Input type="password" name={"password"} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Wiederholen</Form.Label>
            <FormFields.Input type="password" name={"passwordRepeat"} required />
          </Form.Group>
          <Button type="submit" className="d-block" disabled={isSubmitting}>
            Passwort ändern
          </Button>
        </Form>
      )}
    </Formik>
  );
};

const PasswordReset = () => {
  const { state } = useLocation() as { state?: { success?: boolean } };
  return (
    <>
      <Row className={"justify-content-center mt-3"}>
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Passwort vergessen</Card.Title>
              <Routes>
                <Route
                  path="success"
                  element={
                    <div>
                      <p>Die Passwortänderung war erfolgreich</p>
                      <p>Sie können sich nun einloggen.</p>
                      <LoginForm />
                    </div>
                  }
                />
                <Route path=":token" element={<PasswordChangeForm />} />
                <Route index element={state?.success ? <PasswordResetRequested /> : <PasswordRequestForm />} />
                <Route path="*" element={<FileNotFound />} />
              </Routes>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default PasswordReset;
