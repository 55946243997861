import { InventoryType } from "@jas/rnd-game-types/lib/models/Inventory";
import classNames from "classnames";
import * as React from "react";
import * as actions from "../../../../redux/character/actions";
import classed from "../../../../util/classed";
import { useDrop } from "react-dnd";
import { DragTypes, itemInventoryTarget } from "../dnd";

export const Slot = classed.div("slot");

export interface InventorySlotProps {
  inventory: InventoryType;
  slot: number;
  children?: React.ReactNode;
  equipItem: typeof actions.equipItem;
  moveItem: typeof actions.moveItem;
  undressItem: typeof actions.undressItem;
}

export const InventorySlot = (props: InventorySlotProps) => {
  const { children } = props;
  const [{ canDrop, isOver }, drop] = useDrop(() => ({
    accept: [DragTypes.CHARACTER_EQUIPMENT, DragTypes.INVENTORY_ITEM],
    drop: itemInventoryTarget.drop(props),
    collect: itemInventoryTarget.collect,
  }));
  return (
    <Slot className={classNames({ drop: canDrop && isOver })} ref={drop}>
      {children}
    </Slot>
  );
};
