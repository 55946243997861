import { ChatMessage } from "@jas/rnd-game-types/lib/models/Chat";
import { format, parseISO } from "date-fns";
import React, { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import ScrollView from "../../../components/ui/ScrollView";
import classed from "../../../util/classed";
import classNames from "classnames";
import { IoDocument } from "@jas/rnd-game-types/lib";

const Msg = classed.div("chat-message");

export const Message = ({ msg }: { msg: IoDocument<ChatMessage> }) => {
  const date = parseISO(msg.time);
  return (
    <Msg>
      <span className="ts" title={format(date, "HH:mm:ss d. MMM")}>
        {format(date, "HH:mm")}
      </span>
      <Link to={"/game/character/display/" + msg.sender.name}>{msg.sender.name}</Link>
      <span className="colon">: </span>
      <span className="c">{msg.content}</span>
    </Msg>
  );
};

export const ChatHistory = ({ messages, className }: { messages: IoDocument<ChatMessage>[]; className?: string }) => {
  const sv = useRef<ScrollView>(null);
  useEffect(() => {
    sv.current && sv.current.touch();
  }, [messages]);
  return (
    <div className={classNames("chat-history", className)}>
      <ScrollView ref={sv}>
        {messages.map((m, i) => (
          <Message msg={m} key={i} />
        ))}
      </ScrollView>
    </div>
  );
};
