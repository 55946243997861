export const ADD = "app/notification/ADD";
export const REMOVE = "app/notification/REMOVE";

export enum NotificationType {
  SUCCESS = "success",
  ERROR = "danger",
}

export interface Notification {
  type: NotificationType;
  message: string;
  autoclose?: boolean;
}
