import React from "react";
import ReactDOM from "react-dom";
import App from "./modules/app";
import AppInstance from "./app";
import { compose, Store } from "redux";
import { History } from "history";

declare global {
  interface Window {
    app?: AppInstance;
    store?: Store;
    browserHistory?: History;
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }
}

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
