import IoEvents from "@jas/rnd-game-types/lib/io-events";
import { IoDocument } from "@jas/rnd-game-types/lib";
import { Account } from "@jas/rnd-game-types/lib/models/Account";
import { Character } from "@jas/rnd-game-types/lib/models/Character";
import { AnyAction, combineReducers } from "redux";
import { LOGIN_SUCCESS, LOGOUT } from "./types";

const isLoggedIn = (state = false, action: AnyAction) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return true;
    case LOGOUT:
      return false;
  }
  return state;
};

const myAccount = (state: Account | null = null, action: AnyAction): Account | null => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return action.payload.account;
    case LOGOUT:
      return null;
  }
  return state;
};

const createAllowed = (state = false, action: AnyAction) => {
  switch (action.type) {
    case IoEvents.CHARACTER_LIST:
      return action.payload.createAllowed;
    case IoEvents.CHAR_LOGGEDIN:
    case LOGOUT:
      return false;
  }
  return state;
};

const characterList = (state: IoDocument<Character>[] = [], action: AnyAction): IoDocument<Character>[] => {
  switch (action.type) {
    case IoEvents.CHARACTER_LIST:
      return action.payload.characterList;
    case IoEvents.CHAR_LOGGEDIN:
    case LOGOUT:
      return [];
  }
  return state;
};

export default combineReducers({
  isLoggedIn,
  myAccount,
  createAllowed,
  characterList,
});
