import { Inventory } from "@jas/rnd-game-types/lib/models/Inventory";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../../redux";
import * as actions from "../../../../redux/character/actions";
import classed from "../../../../util/classed";
import { BlockLoading } from "../../layout/loading";
import { InventoryItem } from "../item";
import { InventorySlot } from "../slot/inventory";
import "./index.scss";

const WardrobeContainer = classed.div("wardrobe-container");

interface BagProps {
  inventory?: Inventory;
  useCanEquip?: boolean;
  moveItem: typeof actions.moveItem;
  undressItem: typeof actions.undressItem;
  equipItem: typeof actions.equipItem;
}

class Cabinet extends React.Component<BagProps> {
  public static readonly panelCount = 4;

  public state = {
    activeDrawer: 0,
  };

  public render() {
    const { inventory, useCanEquip, moveItem, undressItem, equipItem } = this.props;
    return (
      <>
        <BlockLoading loading={!inventory} />
        <WardrobeContainer>
          <div className="cabinet">
            {inventory &&
              this.inventoryMap().map(({ item, slot }, i) => (
                <InventorySlot inventory={inventory.type} slot={slot} key={i} equipItem={equipItem} moveItem={moveItem} undressItem={undressItem}>
                  {item ? <InventoryItem item={item} inventory={inventory} useItem={() => useCanEquip && equipItem(item)} /> : null}
                </InventorySlot>
              ))}
          </div>
          <div className="panels">
            {[...Array(Cabinet.panelCount)].map((_, i) => (
              <button key={i} className={classNames("drawer", { active: this.state.activeDrawer === i })} onClick={() => this.setState({ activeDrawer: i })} />
            ))}
          </div>
        </WardrobeContainer>
      </>
    );
  }
  protected inventoryMap() {
    const { inventory } = this.props;
    if (!inventory) {
      return [];
    }
    const { activeDrawer } = this.state;
    const panelSize = Math.floor(inventory.size / Cabinet.panelCount);
    const offset = panelSize * activeDrawer;
    const arr = [];
    for (let i = offset, max = Math.min(offset + panelSize, inventory.size); i < max; i++) {
      arr.push({
        slot: i,
        item: inventory.items.find((s) => s.slot === i),
      });
    }
    return arr;
  }
}

export default connect(
  (state: RootState) => ({
    inventory: state.character.wardrobe || undefined,
  }),
  {
    equipItem: actions.equipItem,
    moveItem: actions.moveItem,
    undressItem: actions.undressItem,
  }
)(Cabinet);
