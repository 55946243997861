import { InventoryItemInstance } from "@jas/rnd-game-types/lib/models/Inventory";
import { CharacterSlotProps } from "../slot/character";
import { InventorySlotProps } from "../slot/inventory";
import { InventoryItemProps } from "../item";
import { DragSourceMonitor, DropTargetMonitor } from "react-dnd";

export enum DragTypes {
  INVENTORY_ITEM = "INVENTORY_ITEM",
  CHARACTER_EQUIPMENT = "CHARACTER_EQUIPMENT",
}

export const itemSource = {
  item: ({ item, inventory }: InventoryItemProps) => {
    return {
      item: item as InventoryItemInstance,
      sourceInventory: inventory && inventory.type,
    };
  },
  collect: (monitor: DragSourceMonitor) => ({ isDragging: monitor.isDragging() }),
};
export const itemInventoryTarget = {
  drop:
    (props: InventorySlotProps) =>
    ({ item, sourceInventory }: ReturnType<typeof itemSource.item>, monitor: DropTargetMonitor) => {
      if (monitor.getItemType() === DragTypes.CHARACTER_EQUIPMENT) {
        props.undressItem(item, props.slot);
      } else {
        props.moveItem(item, props.slot, sourceInventory, props.inventory);
      }
    },
  collect: (monitor: DropTargetMonitor) => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver() }),
};
export const itemEquipmentTarget = {
  drop: (props: CharacterSlotProps) =>
    (({ item }: ReturnType<typeof itemSource.item>) => {
      props.equipItem(item, props.type);
    }) as (item: unknown) => void,
  canDrop: (props: CharacterSlotProps) =>
    (({ item }: ReturnType<typeof itemSource.item>) => {
      return props.type === item.item.slot;
    }) as (item: unknown) => boolean,
  collect: (monitor: DropTargetMonitor) => ({ canDrop: monitor.canDrop(), isOver: monitor.isOver() }),
};

// export const collectTarget = (connect: DropTargetConnector, monitor: DropTargetMonitor) => ({
//   connectDropTarget: connect.dropTarget(),
//   isOver: Boolean(monitor.isOver()),
//   canDrop: Boolean(monitor.canDrop())
// });
// export type ItemTargetProps = ReturnType<typeof collectTarget>;
//
// export const collectSource = (connect: DragSourceConnector, monitor: DragSourceMonitor) => ({
//   connectDragSource: connect.dragSource(),
//   connectDragPreview: connect.dragPreview(),
//   isDragging: monitor.isDragging()
// });
// export type ItemSourceProps = ReturnType<typeof collectSource>;
