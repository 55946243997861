import React, { useEffect, useRef } from "react";
import { connect } from "react-redux";
import ShortCut from "../../../components/ShortCut";
import { RootState } from "../../../redux";
import ChatInput from "./input";
import { ChatHistory } from "./messages";
import { toggleChat, send } from "../../../redux/chat/actions";
import { Offcanvas } from "react-bootstrap";

const mapStateToProps = (state: RootState) => ({
  messages: state.chat.messages,
  active: !state.chat.display.minimized,
});
const mapDispatchToProps = {
  toggleChat,
  send,
};

// const ChatPosition = classed.div("chat-position");
// const ChatContainer = classed.div("chat-container");

const ChatFrame = (props: ReturnType<typeof mapStateToProps> & typeof mapDispatchToProps) => {
  const input = useRef<ChatInput>(null);
  useEffect(() => {
    if (props.active) {
      setTimeout(() => {
        if (input.current) {
          input.current.focus();
        }
      }, 0);
    }
  }, [props.active]);
  return (
    <>
      <Offcanvas show={props.active} onHide={props.toggleChat} placement={"bottom"} className={"chat-offcanvas"}>
        {/*<Offcanvas.Header closeButton>
          <Offcanvas.Title>Chat</Offcanvas.Title>
        </Offcanvas.Header>*/}
        <Offcanvas.Body className="d-flex flex-column">
          {/*<ChatPosition className={!props.active ? "min" : undefined}>*/}
          {/*<ChatContainer>*/}
          <ShortCut
            keys={"enter"}
            action={(e) => {
              !props.active && props.toggleChat();
              input.current && input.current.focus();
              e && e.preventDefault();
            }}
          />
          {/*<Toggle active={props.active} toggle={props.toggleChat} />*/}
          {/*<div className="inner">*/}
          <ChatHistory messages={props.messages} className="flex-grow-1" />
          <ChatInput ref={input} send={props.send} />
          {/*</div>*/}
          {/*</ChatContainer>*/}
          {/*</ChatPosition>*/}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(ChatFrame);
