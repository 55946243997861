import * as React from "react";
import { connect } from "react-redux";
import { Navigate, Route, Routes } from "react-router";
import { Link } from "react-router-dom";
import { RootState } from "../../redux";
import CharacterList from "./character/select";
import Inventory from "./inventory";
import GameLayout, { GameContainer, MenuContainer } from "./layout";
import CharacterCreate from "./character/create";
import CharacterDisplay from "./character/display";
import Wardrobe from "./inventory/wardrobe";
import Settings from "./settings";

const GameRoutes = connect((state: RootState) => ({
  charLoggedIn: !!state.character.currentCharacter,
}))((props: { charLoggedIn: boolean }) => {
  const GameApp = () => {
    if (!props.charLoggedIn) {
      return (
        <div>
          Weiterleitung zur <Link to="character/select">Charauswahl</Link>
        </div>
      );
    }
    return (
      <GameLayout>
        <GameContainer>
          <Routes>
            <Route path="/settings" element={<Settings />} />
            <Route path="/character/display" element={<CharacterDisplay />} />
            <Route path="/inventory" element={<Inventory />} />
            <Route path="/wardrobe" element={<Wardrobe />} />
            <Route path="/" element={<Navigate to="/game/character/display" />} />
          </Routes>
        </GameContainer>
      </GameLayout>
    );
  };
  return (
    <>
      <Routes>
        <Route
          path="character/select"
          element={
            <MenuContainer>
              <CharacterList />
            </MenuContainer>
          }
        />
        <Route
          path="character/create"
          element={
            <MenuContainer>
              <CharacterCreate />
            </MenuContainer>
          }
        />
        <Route path="*" element={<GameApp />} />
      </Routes>
    </>
  );
});

export default GameRoutes;
