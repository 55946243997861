import * as React from "react";
import { connect } from "react-redux";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import { Form as FormFields } from "react-bootstrap-formik";
import * as accountActions from "../../redux/account/actions";
import { useTranslate } from "../../hooks/app";
import * as Yup from "yup";
import { Route, Routes, useNavigate } from "react-router";
import { FileNotFound } from "./404";

interface ConnectedProps {
  registerAccount: typeof accountActions.registerAccount;
}

const SignupSchema = Yup.object().shape({
  username: Yup.string().email().required(),
  password: Yup.string().required().min(8),
  passwordRepeat: Yup.string().oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
  terms: Yup.boolean().required().oneOf([true], "Bitte akzeptieren Sie die Nutzungsbedingungen"),
});

const Register = connect(undefined, {
  registerAccount: accountActions.registerAccount,
})(({ registerAccount }: ConnectedProps) => {
  const t = useTranslate();
  const navigate = useNavigate();
  return (
    <Row className={"justify-content-center mt-3"}>
      <Col md={8} lg={6}>
        <Card>
          <Card.Body>
            <Card.Title>Registrierung</Card.Title>
            <Routes>
              <Route path="success" element={<div>{t("register.success")}</div>} />
              <Route
                index
                element={
                  <Formik
                    initialValues={{ username: "", password: "", passwordRepeat: "", terms: false }}
                    validationSchema={SignupSchema}
                    onSubmit={async (values, formikHelpers) => {
                      const result = await registerAccount(values.username, values.password).promise;
                      if (!result.success) {
                        for (const [field, error] of Object.entries(result.errors || [])) {
                          formikHelpers.setFieldError(field, t(error));
                        }
                      } else {
                        navigate("success");
                      }
                    }}
                  >
                    {({ handleSubmit, isSubmitting, values, handleChange, touched, errors }) => (
                      <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                          <Form.Label>E-Mail-Adresse</Form.Label>
                          <FormFields.Input type="email" name={"username"} placeholder="email@example.com" required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Passwort</Form.Label>
                          <FormFields.Input type="password" name={"password"} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>Wiederholen</Form.Label>
                          <FormFields.Input type="password" name={"passwordRepeat"} required />
                        </Form.Group>
                        <Form.Group className="mb-3">
                          <Form.Label>
                            <a href="/agb" target="_blank">
                              AGB
                            </a>
                          </Form.Label>
                          <Form.Check
                            type="checkbox"
                            id="form-check-terms-and-conditions"
                            name={"terms"}
                            label={"gelesen und akzeptiert"}
                            feedback={touched.terms && errors.terms}
                            feedbackType={touched.terms && errors.terms ? "invalid" : undefined}
                            required
                            onChange={handleChange}
                            checked={values.terms}
                          />
                        </Form.Group>
                        <Button type="submit" className="d-block" disabled={isSubmitting}>
                          Registrieren
                        </Button>
                      </Form>
                    )}
                  </Formik>
                }
              />
              <Route path="*" element={<FileNotFound />} />
            </Routes>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
});
export default Register;
