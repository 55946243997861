import classNames, { Argument } from "classnames";
import React from "react";
import domElements from "./domElements";

// type ClassedComponent<T> = React.ExoticComponent<Omit<React.ComponentPropsWithoutRef<T>, "className"> & { className?: Argument }>;
// type BaseClassed<T> = (...className: Argument[]) => ClassedComponent<T>;

function _classed<T extends React.ElementType>(Component: T) {
  return (...className: Argument[]) =>
    React.forwardRef(({ className: propsClassName, ...props }: React.ComponentPropsWithoutRef<T>, ref) =>
      React.createElement(Component, {
        ...props,
        ref,
        className: classNames(...className, propsClassName),
      })
    );
}

type ActualClassed = typeof _classed;

const classed = _classed as ActualClassed & {
  [key in typeof domElements[number]]: ReturnType<ActualClassed>;
};

// Shorthands for all valid HTML Elements
domElements.forEach((domElement) => {
  classed[domElement] = classed(domElement);
});

export default classed;
