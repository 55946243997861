import IoEvents from "@jas/rnd-game-types/lib/io-events";

export const LOGIN = IoEvents.LOGIN;
export const LOGOUT = IoEvents.LOGOUT;
export const LOGIN_SUCCESS = IoEvents.LOGIN_SUCCESS;
export const LOGIN_ERROR = "LOGIN_ERROR";
export const REGISTER = "REGISTER";
export const SELECT_CHARACTER = IoEvents.SELECT_CHARACTER;
export const CREATE_CHARACTER = IoEvents.CREATE_CHARACTER;
export const DELETE_CHARACTER = IoEvents.DELETE_CHARACTER;
export const PASSPORT_LOGIN = IoEvents.PASSPORT_LOGIN;
export const RESET_PASSWORD = IoEvents.RESET_PASSWORD;
export const LOGIN_EXTERNAL = "app/account/LOGIN_EXTERNAL";
