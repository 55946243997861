import { IoDocument } from "@jas/rnd-game-types/lib";
import IoEvents from "@jas/rnd-game-types/lib/io-events";
import { Character } from "@jas/rnd-game-types/lib/models/Character";
import { Inventory, InventoryType } from "@jas/rnd-game-types/lib/models/Inventory";
import { StatSums } from "@jas/rnd-game-types/lib/models/Stats";
import { AnyAction, combineReducers } from "redux";

const stats = (state: StatSums = {}, action: AnyAction): StatSums => {
  switch (action.type) {
    case IoEvents.CHAR_LOGGEDIN:
    case IoEvents.CHAR_UPDATE:
      return action.payload.stats;
    case IoEvents.CHARACTER_LIST:
      return {};
  }
  return state;
};

export interface CharacterClass {
  maxExp: number;
}

const characterClass = (state: CharacterClass | null = null, action: AnyAction): CharacterClass | null => {
  switch (action.type) {
    case IoEvents.CHAR_LOGGEDIN:
    case IoEvents.CHAR_UPDATE:
      return { ...action.payload.class };
    case IoEvents.CHARACTER_LIST:
      return null;
  }
  return state;
};
const currentCharacter = (state: IoDocument<Character> | null = null, action: AnyAction): IoDocument<Character> | null => {
  switch (action.type) {
    case IoEvents.CHAR_LOGGEDIN:
    case IoEvents.CHAR_UPDATE:
      return { ...action.payload.character, lastModified: action.payload.character.lastModified };
    case IoEvents.CHARACTER_LIST:
      return null;
  }
  return state;
};
const inventory = (state: Inventory | null = null, action: AnyAction): Inventory | null => {
  switch (action.type) {
    case IoEvents.INVENTORY_UPDATE:
      if ((action.payload as Inventory).type !== InventoryType.CHARACTER_INVENTORY) {
        return state;
      }
      return action.payload;
    case IoEvents.CHARACTER_LIST:
      return null;
  }
  return state;
};
const wardrobe = (state: Inventory | null = null, action: AnyAction): Inventory | null => {
  switch (action.type) {
    case IoEvents.INVENTORY_UPDATE:
      if ((action.payload as Inventory).type !== InventoryType.WARDROBE) {
        return state;
      }
      return action.payload;
    case IoEvents.CHARACTER_LIST:
      return null;
  }
  return state;
};

export default combineReducers({
  currentCharacter,
  characterClass,
  stats,
  inventory,
  wardrobe,
});
