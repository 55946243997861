import * as React from "react";

const Agb = () => {
  return (
    <>
      <h2>AGB</h2>
      <p>t.b.d</p>
    </>
  );
};
export default Agb;
