import { PayloadAction, TypeConstant } from "typesafe-actions";

export type PromiseWithResolve<T> = Promise<T> & { resolve: (value: T | PromiseLike<T>) => void; reject: (reason?: any) => void };

export type SagaAsync = Generator<any, any, any>;

export interface PromisedAction<R = unknown, TType extends TypeConstant = string, TPayload = unknown> extends PayloadAction<TType, TPayload> {
  promise: PromiseWithResolve<R>;
}

export const ioPromise = <T>() => {
  let resolve: (value: T | PromiseLike<T>) => void, reject: (reason?: any) => void;
  const promise = new Promise<T>((res, rej) => {
    resolve = res;
    reject = rej;
  }) as PromiseWithResolve<T>;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.resolve = resolve!;
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
  promise.reject = reject!;
  return promise;
};
