import { connect } from "react-redux";
import * as accountActions from "../../redux/account/actions";
import * as React from "react";
import { useEffect } from "react";
import { Navigate } from "react-router";

const Logout = connect(undefined, {
  logout: accountActions.logout,
})(({ logout }: { logout: () => void }) => {
  useEffect(logout, [logout]);
  return (
    <>
      <Navigate to="/" />
    </>
  );
});
export default Logout;
