import * as React from "react";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { Container, Navbar } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

export const Logo = ({ className }: { className?: string }) => (
  <h1 className={classNames("logo", className)}>
    <span className="visually-hidden">Risu no Densetsu</span>
  </h1>
);

export const Menu = (props: { children: React.ReactChild[]; className?: string }) => (
  <div className={classNames("menu-container", props.className)}>
    <ul>
      {props.children.map((item, key) => (
        <li key={key}>{item}</li>
      ))}
    </ul>
  </div>
);

export const Sidebar = (props: { children: React.ReactChild[]; className?: string }) => (
  <div className={classNames("sidebar-container", props.className)}>
    <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
      {props.children.map((item, key) => (
        <li key={key} className="nav-link">
          {item}
        </li>
      ))}
    </ul>
  </div>
);

// export const Content = (props: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) => (
//   <div {...props} className={classNames("content", props.className)} />
// );

export const Header = () => (
  <Navbar variant="dark" bg="dark" sticky="top" expand="md" className="bg-header navbar-header p-0 mb-4">
    <Container>
      <LinkContainer to="/">
        <Navbar.Brand className="d-flex align-items-center">
          <Logo />
        </Navbar.Brand>
      </LinkContainer>
      {/*<Navbar.Toggle aria-controls="menu-navbar-nav" />
      <Navbar.Collapse id="menu-navbar-nav">
        <Nav className="ms-auto">
          <LinkContainer to="/agb">
            <Nav.Link>ABG</Nav.Link>
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>*/}
    </Container>
  </Navbar>
);

export const Footer = ({ contained }: { contained?: boolean }) => {
  const content = (
    <div className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4">
      <small className="text-muted order-0">Risu no Densetsu</small>
      <small className="text-muted order-2">
        <Link to="/impressum">Impressum</Link>
      </small>
      <div className="d-md-none w-100 order-3" />
      <small className="text-muted order-4 order-md-1">
        © Copyright 2011-2022 by <a href="http://ja-s.de/">ja-s.de</a>
      </small>
    </div>
  );
  if (contained) {
    return <footer className="mt-auto">{content}</footer>;
  }
  return (
    <footer className="mt-auto">
      <Container>{content}</Container>
    </footer>
  );
};
