import { AnyAction, combineReducers } from "redux";
import { ADD, Notification, REMOVE } from "./types";

const list = (state: Notification[] = [], action: AnyAction): Notification[] => {
  switch (action.type) {
    case ADD:
      return [...state, action.payload];
    case REMOVE:
      return state.filter((n) => n !== action.payload);
  }
  return state;
};

export default combineReducers({
  list,
});
