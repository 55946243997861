import { RootState } from "../index";

export const selectCurrentCharacter = (state: RootState) => {
  const char = state.character.currentCharacter;
  if (!char) {
    throw new Error("Not Logged in");
  }
  return char;
};
export const selectClassInfo = (state: RootState) => {
  const cls = state.character.characterClass;
  if (!cls) {
    throw new Error("Not Logged in");
  }
  return cls;
};
