import * as React from "react";
import { Spinner } from "react-bootstrap";

export const BlockLoading = ({ loading }: { loading: boolean }) => {
  if (!loading) {
    return null;
  }
  return (
    <div className="loading-overlay">
      <Spinner animation="border" variant="secondary">
        <span className="visually-hidden">Loading...</span>
      </Spinner>
    </div>
  );
};
