import { applyMiddleware, compose, createStore } from "redux";
import createSagaMiddleware from "redux-saga";
import { StateType } from "typesafe-actions";
import rootReducer from "./rootReducer";

import { createReduxHistory, routerMiddleware } from "./history";

export default function createRedux() {
  const sagaMiddleware = createSagaMiddleware();
  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  let initState: Partial<RootState> | undefined;
  try {
    const settings = localStorage.getItem("settings");
    if (settings) {
      initState = {
        settings: JSON.parse(settings),
      };
    }
  } catch (e) {
    // ignore
  }

  const store = createStore(rootReducer, initState, composeEnhancers(applyMiddleware(routerMiddleware, sagaMiddleware)));
  if (process.env.NODE_ENV === "development") {
    window.store = store;
  }

  const history = createReduxHistory(store);
  return { sagaMiddleware, store, history };
}

export type RootReducer = typeof rootReducer;
export type RootState = StateType<RootReducer>;
