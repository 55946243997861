import * as React from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { useApp, useTranslate } from "../../hooks/app";
import * as Yup from "yup";
import { Formik } from "formik";
import { Form as FormFields } from "react-bootstrap-formik";
import { addNotification } from "../../redux/notifications/actions";
import { useDispatch } from "react-redux";
import { NotificationType } from "../../redux/notifications/types";

const PasswordChangeSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  password: Yup.string().required().min(8),
  passwordRepeat: Yup.string().oneOf([Yup.ref("password"), null], "Passwörter müssen übereinstimmen"),
});
const PasswordChangeForm = () => {
  const app = useApp();
  const { token } = useParams<{ token: string }>();
  const t = useTranslate();
  const dispatch = useDispatch();

  return (
    <Formik
      initialValues={{ oldPassword: "", password: "", passwordRepeat: "" }}
      validationSchema={PasswordChangeSchema}
      onSubmit={async (values, formikHelpers) => {
        const { data } = (await app.api.post(
          "/account/changepw",
          {
            token,
            oldPassword: values.oldPassword,
            password: values.password,
          },
          {
            headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
          }
        )) as { data: { success: boolean; errors?: { [key: string]: string } } };

        if (data.success) {
          dispatch(addNotification(t("account.passwordChanged"), NotificationType.SUCCESS));
          formikHelpers.resetForm();
        } else {
          for (const [field, error] of Object.entries(data.errors || [])) {
            formikHelpers.setFieldError(field, t(error));
          }
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>Aktuelles Passwort</Form.Label>
            <FormFields.Input type="password" name={"oldPassword"} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Neues Passwort</Form.Label>
            <FormFields.Input type="password" name={"password"} required />
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Wiederholen</Form.Label>
            <FormFields.Input type="password" name={"passwordRepeat"} required />
          </Form.Group>
          <Button type="submit" className="d-block" disabled={isSubmitting}>
            Passwort ändern
          </Button>

          <p className="mt-3">
            <small>
              Solltest du über Discord oder Google angemeldet sein, und dein aktuelles Passwort nicht kennen, melde dich bitte ab, und benutze Passwort
              vergessen, mit deiner E-Mail-Adresse.
            </small>
          </p>
        </Form>
      )}
    </Formik>
  );
};

const Account = () => {
  return (
    <>
      <Row className={"justify-content-center mt-3"}>
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Passwort ändern</Card.Title>
              <PasswordChangeForm />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Account;
