export function colorToInt(color: { r: number; g: number; b: number }) {
  return (color.r << 16) + (color.g << 8) + color.b;
}

export function intToColor(color: number) {
  return {
    r: color >> 16,
    g: (color >> 8) & 255,
    b: color & 255,
  };
}
