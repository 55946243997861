import { useContext } from "react";
import { AppContext } from "../modules/app";

export function useApp() {
  return useContext(AppContext);
}
export function useTranslate() {
  const app = useContext(AppContext);
  return app.t;
}

export function useServerUrl(path?: string) {
  return (process.env.SERVER_URL || "http://localhost:3000") + (path || "");
}
