import { Character, Classes } from "@jas/rnd-game-types/lib/models/Character";
import { LOGIN, SELECT_CHARACTER, LOGOUT, RESET_PASSWORD, PASSPORT_LOGIN, LOGIN_EXTERNAL, CREATE_CHARACTER, DELETE_CHARACTER, REGISTER } from "./types";
import { push } from "redux-first-history";
import { ioPromise } from "../helper";
import { IoDocument } from "@jas/rnd-game-types/lib";

export const logout = () => ({ type: LOGOUT });
export const loginUser = (username: string, password: string) => ({ type: LOGIN, payload: { username, password }, promise: ioPromise<boolean>() });
export const loginExternal = (type: string) => ({ type: LOGIN_EXTERNAL, payload: type });
export const passportLogin = () => ({ type: PASSPORT_LOGIN, payload: undefined, io: true });
export const resetPassword = (username: string) => ({ type: RESET_PASSWORD, payload: { username }, io: true });
export const loginCharacter = (char: IoDocument<Character>) => ({ type: SELECT_CHARACTER, payload: char, io: true });
export const deleteCharacter = (char: IoDocument<Character>) => ({ type: DELETE_CHARACTER, payload: char, io: true });
export const goToCreateCharacter = () => push("/game/character/create");
export const createCharacter = (char: { name: string; class: Classes; hairStyle: number; hairColor: number }) => ({
  type: CREATE_CHARACTER,
  payload: char,
  io: ioPromise<{ success: boolean; name?: string }>(),
});
export const registerAccount = (username: string, password: string) => ({
  type: REGISTER,
  payload: { username, password },
  promise: ioPromise<{ success: boolean; errors?: { [key: string]: string } }>(),
});
