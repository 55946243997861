import React, { useState } from "react";

export function useFormInput(defaultValue = "") {
  const [value, setValue] = useState(defaultValue);
  return {
    value,
    onChange: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement>) => setValue(e.target.value),
  };
}
export function useFormChecked(defaultValue = false) {
  const [value, setValue] = useState(defaultValue);
  return {
    checked: value,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => setValue(e.target.checked),
  };
}
