import * as React from "react";
import classed from "../../../util/classed";
import { InGameHeader, MenuHeader } from "./header";
import { Col, Container, Row } from "react-bootstrap";
import Menu, { UiMenu } from "../menu";
import { Footer } from "../../app/layout";
import ChatFrame from "../chat/frame";
import Notifications from "../../app/notifications";

export const StatusBarContainer = classed.div("status-bar-container");

/**
 * Im-Game UI
 */
export const GameContainer = ({ children }: { children: React.ReactNode }) => (
  <>
    <InGameHeader />
    <Container fluid>
      <Row className="flex-nowrap">
        {/*<Logo />*/}
        {/*<Flex*/}
        {/*  container*/}
        {/*  css={css`*/}
        {/*    padding-top: 70px;*/}
        {/*    min-height: 593px;*/}
        {/*  `}*/}
        {/*>*/}
        <Col xl={2} md={3} className="game-sidebar bg-dark d-flex flex-column flex-shrink-1 flex-lg-shrink-0 w-auto">
          <Menu className="mb-auto flex-shrink-1 flex-nowrap overflow-auto" />
          <hr />
          <UiMenu />
        </Col>
        <Col className="game-content d-flex flex-column px-0 overflow-auto">
          <main role="main" className="game-container position-relative">
            {children}
          </main>
          <Footer />
        </Col>
        {/*</Flex>*/}
      </Row>
      {/*<StatusBarContainer>*/}
      <ChatFrame />
      {/*  <StatusBar />*/}
      {/*</StatusBarContainer>*/}
    </Container>
    <Notifications />
  </>
);

/**
 * Spiele Account Menü
 */
export const MenuContainer = ({ children }: { children: React.ReactNode }) => (
  <>
    <MenuHeader />
    <Container>{children}</Container>
    <Footer />
    <Notifications />
  </>
);

const GameLayout = ({ children }: { children: React.ReactNode }) => (
  <>
    <div id="top" />
    {children}
  </>
);
export default GameLayout;
