import { Classes } from "@jas/rnd-game-types/lib/models/Character";
import React from "react";
import { RGBColor } from "react-color";

const images = {
  1: {
    1: require("@jas/rnd-media/images/character/g/mask_1x1.png"),
    2: require("@jas/rnd-media/images/character/g/mask_1x2.png"),
    3: require("@jas/rnd-media/images/character/g/mask_1x3.png"),
  },
  2: {
    1: require("@jas/rnd-media/images/character/g/mask_2x1.png"),
    2: require("@jas/rnd-media/images/character/g/mask_2x2.png"),
    3: require("@jas/rnd-media/images/character/g/mask_2x3.png"),
  },
  4: {
    1: require("@jas/rnd-media/images/character/g/mask_4x1.png"),
    2: require("@jas/rnd-media/images/character/g/mask_4x2.png"),
    3: require("@jas/rnd-media/images/character/g/mask_4x3.png"),
  },
  8: {
    1: require("@jas/rnd-media/images/character/g/mask_8x1.png"),
    2: require("@jas/rnd-media/images/character/g/mask_8x2.png"),
    3: require("@jas/rnd-media/images/character/g/mask_8x3.png"),
  },
  16: {
    1: require("@jas/rnd-media/images/character/g/mask_16x1.png"),
    2: require("@jas/rnd-media/images/character/g/mask_16x2.png"),
    3: require("@jas/rnd-media/images/character/g/mask_16x3.png"),
  },
};

interface Props {
  hairColor: RGBColor;
  hairStyle: 1 | 2 | 3;
  characterClass: Classes;
}

const mask = {
  width: "150px",
  height: "150px",
  borderRadius: "15px",
};

const ImagePreview = ({ characterClass, hairStyle, hairColor }: Props) => (
  <div>
    <div
      style={{
        ...mask,
        backgroundColor: `rgb(${hairColor.r}, ${hairColor.g}, ${hairColor.b})`,
      }}
    >
      <div style={{ ...mask, backgroundImage: `url(${images[characterClass][hairStyle]})` }} />
    </div>
  </div>
);

export default ImagePreview;
