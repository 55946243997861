import { Character } from "@jas/rnd-game-types/lib/models/Character";
import { ItemSlot, ItemStatType } from "@jas/rnd-game-types/lib/models/Item";
import { StatSum, StatSums } from "@jas/rnd-game-types/lib/models/Stats";
import * as React from "react";
import { connect } from "react-redux";
import Tooltip from "../../../components/Tooltip";
import { useServerUrl, useTranslate } from "../../../hooks/app";
import { RootState } from "../../../redux";
import * as actions from "../../../redux/character/actions";
import { format } from "./helper";
import { EquipmentItem } from "./item";
import { CharacterSlot, CharacterSlotProps } from "./slot/character";
import { selectCurrentCharacter } from "../../../redux/character/selectors";
import classed from "../../../util/classed";
import { IoDocument } from "@jas/rnd-game-types/lib";
import { parseISO } from "date-fns";

const Relief = classed.div("relief");
const ReliefInner = classed.div("relief-inner");

const StatDisplay = ({ children }: { children?: StatSum }) => {
  if (!children) {
    return null;
  }
  return (
    <Tooltip content={format.stat(children.base) + (children.items > 0 ? " +" + format.stat(children.items) : "")}>
      <td>{format.stat(children.base + children.items)}</td>
    </Tooltip>
  );
};
const StatsSummary = ({ stats }: { stats: StatSums }) => {
  const t = useTranslate();

  return (
    <ReliefInner>
      <table>
        <tbody>
          <tr>
            <td>{t("stats.strength")}</td>
            <StatDisplay>{stats[ItemStatType.STRENGTH]}</StatDisplay>

            <td>{t("stats.dexterity")}</td>
            <StatDisplay>{stats[ItemStatType.DEXTERITY]}</StatDisplay>
          </tr>
          <tr>
            <td>{t("stats.intelligence")}</td>
            <StatDisplay>{stats[ItemStatType.INTELLIGENCE]}</StatDisplay>

            <td>{t("stats.health")}</td>
            <StatDisplay>{stats[ItemStatType.HEALTH]}</StatDisplay>
          </tr>
        </tbody>
      </table>
    </ReliefInner>
  );
};

interface EquipmentProps {
  character: IoDocument<Character>;
  stats: StatSums;
  equipItem: typeof actions.equipItem;
}

const Figure = ({ character }: { character: IoDocument<Character> }) => (
  <img src={useServerUrl(`/images/game/c/${character.id}/doll.png?t=${parseISO(character.lastModified).getTime()}`)} alt="" />
);

class Equipment extends React.Component<EquipmentProps> {
  public render() {
    const { character, stats, equipItem } = this.props;
    const RenderCharacterSlot = this.RenderCharacterSlot;
    return (
      <Relief className={"d-flex flex-column character doll mx-auto"}>
        <div className="main">
          <div className="top" style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="pet">
              <RenderCharacterSlot type={ItemSlot.PET} equipItem={equipItem} small />
            </div>
            <div className="middle" style={{ display: "flex" }}>
              <RenderCharacterSlot type={ItemSlot.RING} equipItem={equipItem} small />
              <RenderCharacterSlot type={ItemSlot.AMULET} equipItem={equipItem} small />
              <RenderCharacterSlot type={ItemSlot.JEWEL} equipItem={equipItem} small />
            </div>
            <div className="cos">
              <RenderCharacterSlot type={ItemSlot.COS} equipItem={equipItem} small />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div className="left">
              <RenderCharacterSlot type={ItemSlot.HEAD} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.CHEST} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.GLOVE} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.LEGS} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.FEETS} equipItem={equipItem} />
            </div>
            <div className="figure">
              <Figure character={character} />
            </div>
            <div className="right">
              <RenderCharacterSlot type={ItemSlot.WEAPON} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.CAPE} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.BRACE} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.BELT} equipItem={equipItem} />
              <RenderCharacterSlot type={ItemSlot.SOCKS} equipItem={equipItem} />
            </div>
          </div>
        </div>
        <div className="mt-auto">
          <StatsSummary stats={stats} />
        </div>
      </Relief>
    );
  }

  private RenderCharacterSlot = (props: CharacterSlotProps) => {
    const item = this.props.character.equipment.find((instance) => instance.slot === props.type);
    return <CharacterSlot {...props}>{item ? <EquipmentItem item={item} /> : null}</CharacterSlot>;
  };
}

export default connect(
  (state: RootState) => ({
    character: selectCurrentCharacter(state),
    stats: state.character.stats,
  }),
  {
    equipItem: actions.equipItem,
  }
)(Equipment);
