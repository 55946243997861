import IoEvents from "@jas/rnd-game-types/lib/io-events";
import { LOCATION_CHANGE, push, replace } from "redux-first-history";
import { fork, put, select, take, takeEvery } from "redux-saga/effects";
import App from "../../app";
import { RootState } from "../index";
import { LOGIN, LOGIN_ERROR, LOGIN_EXTERNAL, LOGIN_SUCCESS, LOGOUT, REGISTER } from "./types";
import { PayloadAction } from "typesafe-actions";
import { locationChangeAction } from "redux-first-history/src/actions";
import { PromisedAction, SagaAsync } from "../helper";
import { SagaIterator } from "redux-saga";
import { AnyAction } from "redux";

type LocationChangeAction = ReturnType<typeof locationChangeAction>;

export default function* (app: App) {
  function* authorize(user: string, password: string): SagaAsync {
    try {
      const result = (yield app.api.post("/auth/login", {
        username: user,
        password: password,
      })) as any;
      const { success, error, account, token } = result.data;
      if (success) {
        localStorage.setItem("token", token);
        yield put({ type: LOGIN_SUCCESS, payload: { account, token } });
      } else {
        yield put({ type: LOGIN_ERROR, error });
        return;
      }
    } catch (error) {
      console.error(error);
      yield put({ type: LOGIN_ERROR, error });
    }
  }
  function* reconnect(): SagaAsync {
    try {
      const token = localStorage.getItem("token");
      const result = yield app.api.post("/auth/jwt", undefined, {
        headers: {
          Authorization: "Bearer " + token,
        },
      });
      const { success, error, account } = result.data;
      if (success) {
        yield put({ type: LOGIN_SUCCESS, payload: { account, token } });
      } else {
        yield put({ type: LOGIN_ERROR, error });
        return;
      }
    } catch (error) {
      console.error(error);
      yield put({ type: LOGIN_ERROR, error });
    }
  }
  function* registerComplete(token: string): SagaAsync {
    try {
      const result = yield app.api.post("/register/confirm/" + token);
      const { success, /*error, */ account } = result.data;
      if (success) {
        yield put(push("/register/confirm/success", { username: account.email }));
        // yield put({ type: LOGIN_SUCCESS, payload: { account, token } });
      } else {
        // yield put({ type: LOGIN_ERROR, error });
        return;
      }
    } catch (error) {
      console.error(error);
      // yield put({ type: LOGIN_ERROR, error });
    }
  }

  function* loginFlow() {
    yield takeEvery(LOGIN_EXTERNAL, function ({ payload: type }: PayloadAction<string, string>) {
      window.location.href = process.env.SERVER_URL + "/auth/" + type;
    });
    yield takeEvery(REGISTER, async function ({ payload: data, promise }: PromisedAction) {
      try {
        const result = await app.api.post("/register", data);
        promise.resolve(result.data);
      } catch (e) {
        promise.reject(e);
      }
    });

    yield fork(function* loginPromise(): SagaIterator {
      while (true) {
        const { promise } = yield take(LOGIN);
        const action = (yield take([LOGIN_SUCCESS, LOGIN_ERROR])) as AnyAction;
        promise.resolve(action.type === LOGIN_SUCCESS);
      }
    });

    while (true) {
      const { pathname } = ((yield select((state: RootState) => state.router.location)) as RootState["router"]["location"]) || { pathname: undefined };

      const params = new URLSearchParams(window.location.search);
      if (params.has("token")) {
        yield put(replace(window.location.pathname));
        localStorage.setItem("token", params.get("token") || "");
      }

      const registerToken = pathname?.match(/^\/register\/confirm\/([^/]+)$/);
      if (registerToken && registerToken[1] !== "success") {
        yield fork(registerComplete, registerToken[1]);
      }

      if (localStorage.getItem("token")) {
        yield fork(reconnect);
      } else {
        const {
          type,
          payload: { username, password },
        } = yield take([LOGIN, LOGIN_SUCCESS]);

        if (type === LOGIN) {
          // fork return a Task object
          yield fork(authorize, username, password);
        }
      }
      yield take([LOGOUT, LOGIN_ERROR]);
      app.api.get("/auth/logout").catch((e) => console.error("Logout failed", e));
      localStorage.removeItem("token");
    }
  }

  yield takeEvery(IoEvents.CHAR_LOGGEDIN, function* (): SagaIterator {
    const {
      router: { location },
    }: RootState = yield select();
    if (location?.pathname === "/game/character/select" || location?.pathname.substr(0, 5) !== "/game") {
      yield put(push("/game/character/display"));
    }
  });
  yield takeEvery(IoEvents.CHARACTER_LIST, function* (): SagaIterator {
    const {
      router: { location },
    }: RootState = yield select();
    if (location?.pathname !== "/game/character/select") {
      yield put(push("/game/character/select"));
    }
  });
  yield takeEvery(LOCATION_CHANGE, ({ payload: { location } }: LocationChangeAction) => {
    if (location.pathname === "/game/character/select") {
      app.io?.emit("app/account/SWITCH_CHARACTER");
    }
  });

  yield fork(loginFlow);
}
