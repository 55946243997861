import { AnyAction, combineReducers } from "redux";
import { CONNECTED, CONNECTING, DISCONNECT, RECONNECT, UPDATE } from "./types";
import IoEvents from "@jas/rnd-game-types/lib/io-events";
import { LOGIN_ERROR } from "../account/types";

const updateAvailable = (state = false, action: AnyAction) => {
  switch (action.type) {
    case UPDATE:
      return true;
  }
  return state;
};

/**
 * Solange wir noch auf den Server warten, zeigen wir keine UI und machen keine Browser-Redirects.
 */
const booting = (state: boolean | undefined = undefined, action: AnyAction): boolean => {
  switch (action.type) {
    case CONNECTING:
      return true;
    case IoEvents.CHARACTER_LIST:
    case IoEvents.CHAR_LOGGEDIN:
      return false;
    case LOGIN_ERROR:
      return false;
  }
  if (state === undefined) {
    state = Boolean(localStorage.getItem("token")); // Ohne token im LocalStorage fangen wir ohne Boot an
  }
  return state;
};

const reconnecting = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case RECONNECT:
      return action.payload;
    case DISCONNECT:
    case CONNECTED:
      return false;
  }
  return state;
};

export default combineReducers({
  updateAvailable,
  booting,
  reconnecting,
});
