import * as React from "react";
import { Nav, NavLinkProps as BsNavLinkProps } from "react-bootstrap";
import { NavLink, NavLinkProps } from "react-router-dom";
import classNames from "classnames";

export const TheMenu = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <Nav as="ul" className={classNames("nav-pills flex-column text-decoration-none", className)}>
    {children}
  </Nav>
);
interface MenuItemProps {
  icon?: React.ReactElement;
  disabled?: boolean;
}
export const MenuButton = ({ children, icon, disabled, ...props }: NavLinkProps & MenuItemProps) => {
  if (icon) {
    icon = React.cloneElement(icon, { className: "bi" });
  }
  return (
    <li className="nav-item">
      <NavLink {...props} className={({ isActive: active }) => classNames("nav-link", props.className, { active, disabled })}>
        {icon} <span className="ms-2 d-none d-lg-inline">{children}</span>
      </NavLink>
    </li>
  );
};

export const MenuLink = ({ children, icon, disabled, ...props }: BsNavLinkProps & MenuItemProps) => {
  if (icon) {
    icon = React.cloneElement(icon, { className: "bi" });
  }
  return (
    <Nav.Link {...props} className={classNames(props.className, { disabled })}>
      {icon} <span className="ms-2 d-none d-lg-inline">{children}</span>
    </Nav.Link>
  );
};
