/* eslint-disable import/namespace */
import { ItemSlot } from "@jas/rnd-game-types/lib/models/Item";
import * as slotImageAssets from "./iconassetss";

export type ItemSlotAssetNames = keyof typeof slotImageAssets;

const slotImages = Object.values(ItemSlot).reduce((slotImages: { [key: string | number]: string }, v: string | ItemSlot) => {
  if (typeof v === "number") {
    if (slotImageAssets[ItemSlot[v as ItemSlot].toString().toLowerCase() as ItemSlotAssetNames]) {
      slotImages[v] = slotImageAssets[ItemSlot[v as ItemSlot].toString().toLowerCase() as ItemSlotAssetNames];
    }
  } else {
    if (slotImageAssets[v.toLowerCase() as ItemSlotAssetNames]) {
      slotImages[v] = slotImageAssets[v.toLowerCase() as ItemSlotAssetNames];
      slotImages[v.toLowerCase() as ItemSlotAssetNames] = slotImageAssets[v.toLowerCase() as ItemSlotAssetNames];
    }
  }
  return slotImages;
}, {}) as { [key in Exclude<ItemSlot, ItemSlot.INVENTORY> | ItemSlotAssetNames]: string };

export default slotImages;
