import { Classes } from "./Character";
import { ItemColor } from "./ItemInstance";

export enum ItemSlot {
  INVENTORY = 0,
  WEAPON = 1,
  HEAD = 2,
  CHEST = 4,
  BELT = 8,
  LEGS = 16,
  FEETS = 32,
  JEWEL = 64,
  RING = 128,
  AMULET = 256,
  GLOVE = 512,
  CAPE = 1024,
  BRACE = 2048,
  SOCKS = 4096,
  PET = 8192,
  COS = 16384,
}

export enum WeaponType {
  HEAVY = 1,
  LIGHT = 2,
  MAGIC = 4,
}

export enum ItemType {
  MISC = 0,
  CLOTH = 1,
  LEATHER = 2,
  METAL = 4,

  LIGHT_WEAPON = 1024,
  HEAVY_WEAPON = 2048,
  MAGIC_WEAPON = 4096,
}

export enum ItemQuality {
  NORMAL = 1,
  RARE = 3,
  UNIQUE = 6,
}
export enum ItemDisplayType {
  CHAR,
  ICON,
}

export interface ItemDisplay {
  file: string;
  class: Classes;
  x: number;
  y: number;
  priority: number;
  replaceItems?: ItemSlot;
  color?: ItemColor;
  type?: ItemDisplayType;
  // sort?: number
}

export enum ItemStatType {
  STRENGTH = 1,
  DEXTERITY = 2,
  INTELLIGENCE = 4,
  HEALTH = 8,
  DAMAGE = 1024,
}

export interface ItemStat {
  stat: ItemStatType;
  value: number;
  sort?: number;
}

export interface Item {
  id: string;
  name: string;
  slot: ItemSlot;
  comment?: string;
  classRestriction?: Classes;
  type: ItemType;
  price: number;
  internalNote?: string;
  quality: ItemQuality;
  minLevel?: number;
  displays?: ItemDisplay[];
  stats?: ItemStat[];
}

export function isWeapon(item: Item) {
  return item.type >= ItemType.LIGHT_WEAPON;
}

export function getDamage(item: Item) {
  return (item.stats || []).filter((s) => s.stat === ItemStatType.DAMAGE);
}
export function getVisibleStats(item: Item) {
  return (item.stats || []).filter((s) => s.stat !== ItemStatType.DAMAGE);
}
