import { CHAT_MSG, SEND_MSG, TOGGLE_CHAT } from "./types";
import { ChatMessage } from "@jas/rnd-game-types/lib/models/Chat";

interface Msg {
  room?: string;
  content: string;
}

export const newChatMsg = (msg: ChatMessage) => ({ type: CHAT_MSG, payload: msg });
export const send = (msg: Msg) => ({ type: SEND_MSG, payload: msg });
export const toggleChat = () => ({ type: TOGGLE_CHAT });
