import { useDragLayer, XYCoord } from "react-dnd";
import { DragInventoryItem } from "../item";
import { ItemInstance } from "@jas/rnd-game-types/lib/models/ItemInstance";
import { Identifier } from "dnd-core";
import { DragTypes } from "./index";

const layerStyles: React.CSSProperties = {
  position: "fixed",
  pointerEvents: "none",
  zIndex: 100,
  left: 0,
  top: 0,
  width: "100%",
  height: "100%",
};

function getItemStyles(props: CustomDragLayerProps) {
  const { initialOffset, currentOffset } = props;
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;
  return {
    transform,
    WebkitTransform: transform,
  };
}

export interface CustomDragLayerProps {
  data: { item: ItemInstance };
  itemType?: Identifier | null;
  initialOffset: XYCoord | null;
  currentOffset: XYCoord | null;
  isDragging?: boolean;
}

const CustomDragLayer = () => {
  const props: CustomDragLayerProps = useDragLayer((monitor) => ({
    data: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));
  const { itemType, isDragging, data } = props;

  function renderItem() {
    switch (itemType) {
      case DragTypes.INVENTORY_ITEM:
      case DragTypes.CHARACTER_EQUIPMENT:
        return <DragInventoryItem item={data.item} />;
      default:
        return null;
    }
  }

  if (!isDragging) {
    return null;
  }
  return (
    <div style={layerStyles}>
      <div style={getItemStyles(props)}>{renderItem()}</div>
    </div>
  );
};
export default CustomDragLayer;
