import { ItemSlot } from "@jas/rnd-game-types/lib/models/Item";
import classNames from "classnames";
import * as React from "react";
import Tooltip from "../../../../components/Tooltip";
import * as actions from "../../../../redux/character/actions";
import classed from "../../../../util/classed";
import slotImages from "./icons";
import { useTranslate } from "../../../../hooks/app";
import { DragTypes, itemEquipmentTarget } from "../dnd";
import { useDrop } from "react-dnd";

export interface CharacterSlotProps {
  type: ItemSlot;
  children?: React.ReactNode;
  small?: boolean;
  equipItem: typeof actions.equipItem;
}

const EquipmentSlot = classed.div("equipment-slot");
const EquipmentSmallSlot = classed(EquipmentSlot)("small");

export const CharacterSlot = (props: CharacterSlotProps) => {
  const [{ canDrop }, drop] = useDrop(
    () => ({
      accept: DragTypes.INVENTORY_ITEM,
      drop: itemEquipmentTarget.drop(props),
      canDrop: itemEquipmentTarget.canDrop(props),
      collect: itemEquipmentTarget.collect,
    }),
    [props.type, props.equipItem]
  );
  const { type, children, small } = props;
  const typeName = ItemSlot[type].toLowerCase() as keyof typeof slotImages;
  const label = "slots." + typeName;
  const Component = small ? EquipmentSmallSlot : EquipmentSlot;
  const t = useTranslate();
  return children ? (
    <Component ref={drop} className={classNames({ drop: canDrop })}>
      <div className="inner filled">{children}</div>
    </Component>
  ) : (
    <Tooltip content={t(label)}>
      <Component ref={drop} className={classNames({ drop: canDrop })}>
        <div className="inner empty">{slotImages[typeName] ? <img src={slotImages[typeName]} alt="" className="nodrag" /> : null}</div>
      </Component>
    </Tooltip>
  );
};
