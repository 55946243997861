// eslint-disable-next-line import/no-anonymous-default-export
export default {
  base: {
    home: "Home",
    login: "Login",
    register: "Registrierung",
    logout: "Logout",
    next: "weiter",
    forum: "Forum",
    help: "Hilfe",
    admin: "Admin",
    settings: "Optionen",
  },
  notification: {
    title: {
      update: "Update verfügbar",
      danger: "Fehler",
      success: "Erfolg",
    },
  },
  login: {
    title: "Login",
    username: "E-Mail-Adresse",
    password: "Passwort",
    rememberme: "Eingeloggt bleiben",
    submit: "Einloggen",
    error: "Ungültige E-Mail-Adresse oder Passwort",
  },
  register: {
    title: "Registrierung",
    submit: "Registrieren",
    success: "Registrierung erfolgreich. Bitte prüfe deine E-Mails.",
    // "accept_agb": "<a href=\"%path%\" target=\"_blank\">AGB</a> gelesen und aktzeptiert",
    activate_failed: "Aktivierung fehlgeschlagen",
    activate_success: "Das Konto wurde freigeschaltet. Du kannst dich nun einloggen.",
    mail_subject: "[risu no densetsu] Registrierung",
    confirm: "Wiederhohlen",
    errors: {
      taken: "Diese E-Mail-Adresse ist bereits registriert.",
    },
  },
  account: {
    passwordChanged: "Das Passwort wurde erfolgreich geändert.",
  },
  resetpw: {
    title: "Passwort vergessen?",
    title2: "Neues Passwort setzen",
    submit: "Neues Passwort anfordern",
    submit2: "Passwort setzen.",
    mail_subject: "[risu no densetsu] Passwort vergessen",
    initied: "Das Zurücksetzen wurde angestoßen. Bitte prüfe deine E-Mails.",
    success: "Das neue Passwort wurde gesetzt. Du kannst dich nun einloggen.",
  },
  settings: {
    changepw: {
      title: "Passwort ändern",
      submit: "Passwort ändern",
      success: "Das Passwort wurde geändert",
    },
    cancle: {
      title: "Account kündigen",
      submit: "Kündigen",
      success: "Das Konto wurde erfolgreich gelöscht",
    },
    fullscreen: "Vollbild",
  },
  default: "Standard",
  character: {
    name: "Name",
    class: "Klasse",
    level: "Level",
    hair_color: "Haarfarbe",
    hair_style: "Frisur",
    create: {
      name: "Neuer Charakter",
      link: "Charakter erstellen",
      title: "Charakter-Erstellung",
      submit: "Erstellen",
      error: {
        taken: "Der Charaktername ist bereits vergeben",
        limit: "Du hast bereits die maximale Anzahl an Charakteren erstellt",
        illegal: "Der Charaktername entspricht nicht den Richtlinien",
      },
    },
    delete: {
      link: "Löschen",
      title: "Charakter löschen",
      confirm: "Bestätigung das der Charakter »%{name}« gelöscht werden soll",
      submit: "Löschen",
      abort: "Nicht löschen",
    },
    select: "Auswählen",
    change: "Charakter wechseln",
    limit: "Charakter-Limit: %c%",
    info: "Info",
    exp: "Erfahrung",
    ep: "Erfahrungspunkte",
    credits: "Münzen",
    preview: "Vorschau",
  },
  chat: {
    submit: "Senden",
    connection_error: "Chat-Verbindung abgebrochen",
    reconnect: "Wiederverbinden",
    minimize: "Minimieren",
    restore: "Wiederherstellen",
    minimize_help: "Chat minimieren/wiederherstellen [C]",
    general: "Allgemein",
    trade: "Handel",
    guild: "Gilde",
    whispers: "Flüstern",
  },
  game: {
    character: "Charakter",
    inventory: "Inventar",
    wardrobe: "Kleiderschrank",
    quests: "Aufträge",
    dungeon: "Dungeon",
    market: "Marktplatz",
    arena: "Arena",
    mail: "Post",
    guild: "Gilde",
    shop: "Händler",
    map: "Karte",
    halloffame: "Ruhmeshalle",
    guard: "Wachdienst",
  },
  shop: {
    title: "Händler",
    buy: "Kaufen",
    sell: "Verkaufen / Rückkaufen",
    filter: {
      usable_only: "Nur verwendbares",
    },
    barber: {
      title: "Frisör",
      accept: "Annehmen",
    },
  },
  item: {
    class_restriction: "Klasse",
    sell_price: "Verkaufspreis",
    credits: "Münzen",
    not_sellable: "Nicht verkäuflich",
    color: "Farbe",
    id: "Item-ID",
    damage: "Schaden",
    effects: {
      pigment_confirm: "Soll der Gegenstand %item% wirklich %color% gefärbt werden? Der Farbstoff wird dabei verbraucht.",
    },
  },
  form: {
    // "field_requried": "Pflichtfeld bitte ausfüllen"
    field_requried: "Pflichtfeld bitte ausfüllen",
  },
  abort: "Abbrechen",
  costs: "Kosten",
  toplist: {
    pos: "Pos.",
    level_range: "Level-Bereich",
    search: {
      header: "Filter",
      level_min: "Level von",
      level_max: "bis",
      button: "Filtern",
      multiselect: "Mehrere Klassen können durch gedrückthalten von Strg ausgewählt werden.",
    },
  },
  quest: {
    accept: "Annehmen",
    selection: "Auftrags-Auswahl",
    minutes: "Min.",
    rewards: "Belohnung",
    reward: {
      exp: "Erfahrung",
      money: "Münzen",
    },
    description: "Auftrag",
    step1: "Anfang",
    step2: "Schritt 2",
    step3: "Schritt 3",
    finish: "Abschluss",
  },
  dungeon: {
    selection: "Dungeon-Auswahl",
    minlevel: "Dungeons sind erst ab Level 10 verfügbar.",
    enter: "Betreten",
    step: "Abzweigungen genommen",
    walking: "Fortbewegen...",
    level_range: "Level %min% bis %max%",
    dungeon1: {
      title: "Keller",
      description: "Dies ist die Beschreibung des ersten Dungeons. Bla blubb\nWas auch immer...\n",
    },
    turn: {
      forward: "Geradeaus gehen",
      rear_left: "Hinten links abbiegen",
      rear_right: "Hinten rechts abbiegen",
      front_left: "Links abbiegen",
      front_right: "Rechts abbiegen",
    },
    reward: {
      label: "Du findest...",
      opponent: "Gegner",
      battle: "Angreifen",
      treasure: "eine Truhe",
      treasure_content: "Truheninhalt",
      open: "Öffnen",
      nothing: "... nur Staub",
      exp: "Erhaltene Erfahrung",
    },
  },
  tooltip: {
    id: "ID",
  },
  combatlog: {
    view: {
      simple: "Einfache Ansicht",
      advanced: "Erweiterte Ansicht",
    },
  },
  combat: {
    attacker: "Angreifer",
    against: "Gegner",
    duration: "Dauer",
    winner: "Sieger",
    rounds: "{0} Sofort|{1} Eine Runde|]1,Inf] %count% Runden",
    reward: "Belohnung",
    winners: {
      me: "Du",
      tie: "Unentschieden",
    },
    health: "HP",
  },
  market: {
    title: "Marktplatz",
    booth: {
      title: "Eigener Markt-Stand",
      items: "",
      his_booth: "Marktstand von %player%",
    },
    catalog: {
      name: "Gegenstand",
      price: "Preis",
      seller: "Verkäufer",
    },
    search: {
      name: "Gegenstands-Name",
      level: "Level",
      class: "Klasse",
      type: "Art",
      button: "Suchen",
      all: " - Alle - ",
    },
    log: {
      title: "Markstand-Archiv",
      sell: "Ihr habt den Gegenstand %item% für %price% eingestellt.",
      cancel: "Ihr habt den Gegenstand %item% (%price%) vom Marktplatz entfernt.",
      selled: "Euer Gegenstand %item% wurde von %player% für %price% gekauft.",
      buyed: "Ihr habt den Gegenstand %item% von %player% für %price% gekauft.",
    },
  },
  map: {
    zoom_in: "Vergrößern",
    zoom_hint: "Zum zoomen Maus gedrückt halten.",
    doubletap_hint: "Zum Zone wechseln Doppel-Tippen.",
    merchants: "Verfügbare Händler",
    travel_duration: "Entfernung",
    travel_seconds: "Sekunden",
    walking: "Reisen...",
    no_travel_path: "Kein Pfad verfügbar",
  },
  mail: {
    last_message: "Letzte Nachricht",
    unread_count: "Anzahl ungelesene Nachrichten",
    messages: "Nachrichten",
    send: "Senden",
    unread_messages: "%count% ungelesene Nachricht|%count% ungelesene Nachrichten",
  },
  delete: "Löschen",
  commands: {
    available: "Verfügbare Chat-Befehle",
    who: "Charakter-Ansicht öffnen",
    me: "Nachricht als Emote schreiben",
  },
  return: {
    done: "Ausgeführt",
    files_deleted: "Dateien gelöscht.",
  },
  err: {
    unknown_command: "Unbekannter Befehl",
    "who.notfound": "Charakter nicht gefunden",
    "whisper.notfound": "Charakter nicht gefunden",
  },
  classes: {
    SHAPESHIFTER: "Gestaltwandlerin",
    GHOST: "Ruhelose",
    WARRIOR: "Wüstenkämpfer",
    FORESTFAIRY: "Waldelfe",
    MAGE: "Arkanfee",
    shorts: {
      SHAPESHIFTER: "GW",
      GHOST: "RL",
      WARRIOR: "WK",
      FORESTFAIRY: "WF",
      MAGE: "AF",
    },
  },
  slots: {
    inventory: "Inventar",
    weapon: "Waffe",
    head: "Helm",
    chest: "Oberteil",
    belt: "Gürtel",
    legs: "Hose",
    feets: "Schuhe",
    jewel: "Armreif",
    ring: "Ring",
    amulet: "Amulett",
    glove: "Handschuhe",
    cape: "Umhang",
    brace: "Armschienen",
    socks: "Strümpfe",
    pet: "Begleiter",
    cos: "Kostüm",
  },
  stats: {
    strength: "Stärke",
    dexterity: "Geschicklichkeit",
    intelligence: "Intelligenz",
    health: "Gesundheit",
    damage: "Schaden",
  },
  types: {
    misc: "Verschiedens",
    cloth: "Stoff",
    leather: "Leder",
    metal: "Metall",
    light_weapon: "Leichte",
    heavy_weapon: "Schwere",
    magic_weapon: "Magische",
  },
  qualities: {
    normal: "Normal",
    rare: "Selten",
    unique: "Einzigartig",
  },
  hairstyle: {
    simple: "Einfach",
    long: "Lang",
    short: "Kurz",
    extended: "Aufwendig",
  },
  quest_type: {
    combat: "Kampf",
    search: "Suchen & Sammeln",
    hunt: "Jagd (Suchen & Töten)",
  },
  color: {
    none: "Keine",
    red: "Rot",
    green: "Grün",
    blue: "Blau",
    yellow: "Gelb",
    violet: "Violett",
    pink: "Pink",
    turquoise: "Türkis",
    orange: "Orange",
    black: "Schwarz",
    white: "Weiß",
    gold: "Gold",
    silver: "Silber",
  },
  ui: {
    confirm: {
      title: "Sind Sie sich sicher?",
      text: "Soll diese Aktion tatsächlich durchgeführt werden?",
      submit: "Bestätigen",
    },
  },
};
