import { ItemDataSerializer } from "@jas/rnd-game-types/lib/ItemData";
import { ItemQuality } from "@jas/rnd-game-types/lib/models/Item";
import { ItemInstance } from "@jas/rnd-game-types/lib/models/ItemInstance";
import * as React from "react";
// import { DragSource } from "react-dnd";
import { useServerUrl } from "../../../hooks/app";
// import { ItemSourceProps } from "./dnd";
import ItemTooltip from "./tooltip";
import { Inventory } from "@jas/rnd-game-types/lib/models/Inventory";
import classNames from "classnames";
import classed from "../../../util/classed";
import { useDrag } from "react-dnd";
import { DragTypes, itemSource } from "./dnd";

export function qualityClassName(quality: ItemQuality) {
  return ItemQuality[quality].toLowerCase();
}

const Symbol = classed.div("item-symbol");
const BorderSymbol = React.forwardRef<HTMLDivElement, { quality: ItemQuality; isDragged?: boolean } & React.HTMLAttributes<HTMLDivElement>>(
  ({ quality, isDragged, ...props }, ref) => (
    <div ref={ref} {...props} className={classNames(props.className, "border-symbol", { dragging: isDragged }, qualityClassName(quality))} />
  )
);
const CharacterSymbol = classed(BorderSymbol)("character-symbol");
const DragSymbol = classed(BorderSymbol)("drag-symbol");

interface EquipmentItemProps {
  item: ItemInstance;
  children?: React.ReactNode;
}
export interface InventoryItemProps {
  item: ItemInstance;
  inventory?: Inventory;
  useItem?: () => void;
  children?: React.ReactNode;
}

const ItemImage = ({ item }: { item: ItemInstance }) => {
  let url: string;
  if (!ItemDataSerializer.empty(item.itemData)) {
    url = `/images/game/i/${item.item.id}/${ItemDataSerializer.stringify(item.itemData)}/icon.png`;
  } else {
    url = `/images/game/i/${item.item.id}/icon.png`;
  }
  return <img src={useServerUrl(url)} alt="" height={96} width={96} />;
};
export const ItemSymbol = ({ item, border = true }: { item: ItemInstance; border?: boolean }) => {
  if (border) {
    return (
      <BorderSymbol quality={item.item.quality}>
        <ItemImage item={item} />
      </BorderSymbol>
    );
  }
  return (
    <Symbol>
      <ItemImage item={item} />
    </Symbol>
  );
};
export const EmptyImage = React.forwardRef<HTMLImageElement>((props, ref) => (
  <img src={"data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="} alt="" {...props} className={"drag-empty-image"} ref={ref} />
));
export const InventoryItem = ({ item, inventory, useItem, ...props }: InventoryItemProps & Omit<React.HTMLAttributes<HTMLDivElement>, "children">) => {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragTypes.INVENTORY_ITEM,
      item: itemSource.item({ item, inventory }),
      collect: itemSource.collect,
    }),
    [item, inventory]
  );
  return (
    <>
      <EmptyImage ref={preview} />
      <ItemTooltip instance={item} hide={isDragging}>
        <BorderSymbol quality={item.item.quality} isDragged={isDragging} ref={drag} onDoubleClick={useItem} {...props}>
          <ItemImage item={item} />
        </BorderSymbol>
      </ItemTooltip>
    </>
  );
};
export const EquipmentItem = ({ item, ...props }: EquipmentItemProps & Omit<React.HTMLAttributes<HTMLDivElement>, "children">) => {
  const [{ isDragging }, drag, preview] = useDrag(
    () => ({
      type: DragTypes.CHARACTER_EQUIPMENT,
      item: itemSource.item({ item }),
      collect: itemSource.collect,
    }),
    [item]
  );
  return (
    <>
      <EmptyImage ref={preview} />
      <ItemTooltip instance={item} hide={isDragging}>
        <CharacterSymbol quality={item.item.quality} isDragged={isDragging} ref={drag} {...props}>
          <ItemImage item={item} />
        </CharacterSymbol>
      </ItemTooltip>
    </>
  );
};

export const DragInventoryItem = ({ item }: { item: ItemInstance }) => {
  return (
    <DragSymbol quality={item.item.quality}>
      <ItemImage item={item} />
    </DragSymbol>
  );
};
