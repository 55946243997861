import IoEvents from "@jas/rnd-game-types/lib/io-events";
import { LOCATION_CHANGE } from "redux-first-history";
import { AnyAction } from "redux";
import { put, call, select, take, takeEvery } from "redux-saga/effects";
import App from "../../app";
import { RootState } from "../index";
import { SagaIterator } from "redux-saga";

export default function* (app: App): SagaIterator {
  yield take(IoEvents.CHAR_LOGGEDIN);
  const worker = function* (): SagaIterator {
    if (!(yield select((s: RootState) => s.character.wardrobe))) {
      const result = yield call([app, app.call], IoEvents.FETCH_WARDROBE);
      yield put({ type: IoEvents.INVENTORY_UPDATE, payload: result });
    }
  };
  if ((yield select((s: RootState) => s.router.location?.pathname)) === "/game/wardrobe") {
    yield call(worker);
  }
  yield takeEvery(({ type, payload }: AnyAction) => type === LOCATION_CHANGE && payload.location.pathname === "/game/wardrobe", worker);
}
