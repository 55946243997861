import * as React from "react";
import ShortCut from "../../../components/ShortCut";
import { send } from "../../../redux/chat/actions";
import { ArrowReturnLeft } from "react-bootstrap-icons";
import { Button, FormControl, InputGroup } from "react-bootstrap";

// const ChatInputContainer = classed.div("chat-input-container");
// const Input = classed.input("chat-input");
// const Submit = (props: React.HTMLProps<HTMLInputElement>) => <input src={chat_submit} type={"image"} alt="&#9166;" {...props} className={"chat-submit"} />;

export default class ChatInput extends React.Component<{ send: typeof send }> {
  public state = { val: "" };
  public el = React.createRef<HTMLInputElement>();

  public focus() {
    this.el.current && this.el.current.focus();
  }

  public onChange = (e: React.ChangeEvent<HTMLInputElement>) => this.setState({ val: e.target.value });

  public onSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    if (this.state.val) {
      this.props.send({
        room: "general",
        content: this.state.val,
      });
    }

    e.preventDefault();
    this.setState({ val: "" });
  };

  public render() {
    return (
      <>
        {/*<ChatInputContainer>*/}
        <ShortCut
          keys={"escape"}
          action={() => {
            this.setState({ val: "" });
            this.el.current && this.el.current.blur();
          }}
          disabled={() => document.activeElement !== this.el.current}
        />
        <form onSubmit={this.onSubmit}>
          <InputGroup size={"sm"}>
            <FormControl
              type="text"
              value={this.state.val}
              onChange={this.onChange}
              autoComplete="off"
              maxLength={180}
              ref={this.el}
              className={"chat-input"}
            />
            {/*<Submit alt="Senden" value="send" />*/}
            <Button type="submit" className="chat-submit" variant="secondary">
              <ArrowReturnLeft />
            </Button>
          </InputGroup>
        </form>
        {/*</ChatInputContainer>*/}
      </>
    );
  }
}
