import React from "react";
import { OverlayTrigger, Tooltip as BsTooltip } from "react-bootstrap";
import classNames from "classnames";

export interface TooltipProps {
  content: JSX.Element | string;
  children: React.ReactElement;
  className?: string;
}
const Tooltip = ({ content, children, className }: TooltipProps) => (
  <OverlayTrigger overlay={<BsTooltip className={className}>{content}</BsTooltip>}>{children}</OverlayTrigger>
);
export const AdvancedTooltip = ({ content, children, className }: TooltipProps) => (
  <OverlayTrigger placement="right-start" flip={true} overlay={<BsTooltip className={classNames("advanced-tooltip", className)}>{content}</BsTooltip>}>
    {children}
  </OverlayTrigger>
);

export default Tooltip;
