import { IoDocument } from "@jas/rnd-game-types/lib";
import { ItemDataSerializer } from "@jas/rnd-game-types/lib/ItemData";
import { Character, Classes } from "@jas/rnd-game-types/lib/models/Character";
import { getDamage, getVisibleStats, isWeapon, Item, ItemQuality, ItemSlot, ItemStatType, ItemType } from "@jas/rnd-game-types/lib/models/Item";
import { ItemColor, ItemData, ItemInstance } from "@jas/rnd-game-types/lib/models/ItemInstance";
import classNames from "classnames";
import * as React from "react";
import { connect } from "react-redux";
import coin_12 from "../../../assets/coin_12.png";
import { AdvancedTooltip, TooltipProps } from "../../../components/Tooltip";
import { useTranslate } from "../../../hooks/app";
import { RootState } from "../../../redux";
import { format } from "./helper";
import { ItemSymbol, qualityClassName } from "./item";

interface ItemProps {
  item: Item;
  itemData: ItemData;
  showPrice?: boolean;
  className?: string;
  currentCharacter?: IoDocument<Character>;
}

const filterColors = (label: string) => <span>{label}</span>;

const ClassRestrictions = ({ children, currentClass }: { children?: Classes; currentClass?: Classes }) => {
  const t = useTranslate();
  if (!children) {
    return null;
  }

  const rest = Object.entries(Classes)
    .filter(([, v]) => typeof v === "number")
    .filter(([, cr]) => (children & (cr as number)) !== 0)
    .map(([key]) => key) as string[];

  return (
    <div className={classNames("class", { needed: currentClass !== undefined && (children & currentClass) === 0 })}>
      {t("item.class_restriction")}:{" "}
      {rest.map((classType, i) => (
        <React.Fragment key={i}>
          {i > 0 ? ", " : null}
          {t("classes." + classType)}
        </React.Fragment>
      ))}
    </div>
  );
};

const ItemTooltipContent = ({ item, itemData, className, currentCharacter, ...options }: ItemProps) => {
  const t = useTranslate();

  return (
    <div className={classNames("item-tooltip", ItemQuality[item.quality].toLowerCase(), className)} data-id={item.id}>
      <div className="icon">
        <ItemSymbol item={{ item, itemData }} border={false} />
      </div>
      <div className="name">{filterColors(item.name)}</div>
      <div className="types">
        {item.type > 0 ? <span className="type">{t("types." + ItemType[item.type].toLowerCase())}</span> : null}{" "}
        {item.slot > 0 ? <span className="slottype">{t("slots." + ItemSlot[item.slot].toLowerCase())}</span> : null}
      </div>

      {isWeapon(item) && (
        <div className="damage">
          {getDamage(item).map((d, i) => (
            <React.Fragment key={i}>
              {i > 0 ? "-" : ""}
              {d.value}
            </React.Fragment>
          ))}{" "}
          {t("item.damage")}
        </div>
      )}
      {getVisibleStats(item).map((stat, i) => (
        <div className={"stat " + ItemStatType[stat.stat].toLowerCase()} key={i}>
          +{stat.value} {t("stats." + ItemStatType[stat.stat].toLowerCase())}
        </div>
      ))}
      {item.comment && <div className="comment">»{item.comment}«</div>}

      <ClassRestrictions currentClass={currentCharacter ? currentCharacter.class : undefined}>{item.classRestriction}</ClassRestrictions>

      {itemData && (
        <>
          {itemData.color && (
            <div className="color">
              {t("item.color")}: <span className={ItemColor[itemData.color].toLowerCase()}>{t("color." + ItemColor[itemData.color].toLowerCase())}</span>
            </div>
          )}
        </>
      )}

      {options.showPrice &&
        (item.price ? (
          <div className="price">
            {t("item.sell_price")}: {format.price(item.price)} <img src={coin_12} className="coin" alt={t("item.credits")} />
          </div>
        ) : (
          <div className="price notapplicable">{t("item.not_sellable")}</div>
        ))}

      {process.env.NODE_ENV === "development" && (
        <div className="debug">
          <div className="itemid">
            <span className="label">{t("tooltip.id")}:</span> [item:{item.id}
            {!ItemDataSerializer.empty() && ":" + ItemDataSerializer.stringify(itemData)}]
          </div>
        </div>
      )}
    </div>
  );
};

const QualityTooltip = ({ quality, ...props }: { quality: ItemQuality } & TooltipProps) => (
  <AdvancedTooltip {...props} className={classNames("item-tooltip-overlay", qualityClassName(quality))} />
);

interface ItemTooltipProps {
  item?: Item;
  hide?: boolean;
  itemData?: ItemData;
  instance?: ItemInstance;
  showPrice?: boolean;
  ignoreCurrentCharacter?: boolean;
  className?: string;
  children: React.ReactElement<unknown>;
  currentCharacter?: IoDocument<Character>;
}
const ConnectItemTooltip = ({ item, itemData, instance, children, hide, currentCharacter, ignoreCurrentCharacter, ...props }: ItemTooltipProps) => {
  if (instance) {
    item = instance.item;
    itemData = instance.itemData;
  } else {
    itemData = itemData || {};
  }
  if (!item) {
    throw new Error("Neither item nor instance given.");
  }

  if (hide) {
    return <>{children}</>;
  }
  if (ignoreCurrentCharacter) {
    currentCharacter = undefined;
  }

  return (
    <QualityTooltip content={<ItemTooltipContent item={item} itemData={itemData} currentCharacter={currentCharacter} {...props} />} quality={item.quality}>
      {children}
    </QualityTooltip>
  );
};
const ItemTooltip = connect((state: RootState) => ({
  currentCharacter: state.character.currentCharacter || undefined,
}))(ConnectItemTooltip);

export default ItemTooltip;
