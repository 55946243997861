import * as React from "react";
import { connect } from "react-redux";
import { Form as FormFields } from "react-bootstrap-formik";
import * as accountActions from "../../redux/account/actions";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./login.scss";
import { Discord, Google } from "react-bootstrap-icons";
import * as Yup from "yup";
import { Formik } from "formik";
import { useTranslate } from "../../hooks/app";

const LoginSchema = Yup.object().shape({
  username: Yup.string().email().required(),
  password: Yup.string().required(),
});

export const LoginForm = connect(undefined, {
  loginUser: accountActions.loginUser,
})(({ loginUser, afterPassword, children }: { loginUser: typeof accountActions.loginUser; afterPassword?: React.ReactNode; children?: React.ReactNode }) => {
  const t = useTranslate();
  return (
    <Formik
      initialValues={{ username: "", password: "" }}
      validationSchema={LoginSchema}
      onSubmit={async (values, formikHelpers) => {
        const success = await loginUser(values.username, values.password).promise.catch(() => false);
        if (!success) {
          formikHelpers.setFieldError("password", t("login.error"));
        }
      }}
    >
      {({ handleSubmit, isSubmitting }) => (
        <Form onSubmit={handleSubmit}>
          <Form.Group className="mb-3">
            <Form.Label>E-Mail-Adresse</Form.Label>
            <FormFields.Input type="email" name="username" placeholder="email@example.com" required />
          </Form.Group>
          <Form.Group className="mb-1">
            <Form.Label>Passwort</Form.Label>
            <FormFields.Input type="password" name="password" required />
          </Form.Group>
          {afterPassword}

          <div className="d-grid gap gap-3 mt-2">
            <Button type="submit" disabled={isSubmitting}>
              Einloggen
            </Button>
            {children}
          </div>
        </Form>
      )}
    </Formik>
  );
});

const Login = connect(undefined, {
  loginExternal: accountActions.loginExternal,
})(({ loginExternal }: { loginExternal: typeof accountActions.loginExternal }) => {
  return (
    <>
      <Row className={"justify-content-center mt-3"}>
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Login</Card.Title>
              <LoginForm
                afterPassword={
                  <small className={"d-block mb-3"}>
                    <Link to={"/password-reset"}>Passwort vergessen?</Link>
                  </small>
                }
              >
                <hr />
                <Button className="google-btn" onClick={() => loginExternal("google")}>
                  <Google className={"bi"} /> Mit Google anmelden
                </Button>
                <Button className="discord-btn" onClick={() => loginExternal("discord")}>
                  Mit <Discord className={"bi"} /> Discord anmelden
                </Button>
                <hr />

                <Link to={"/register"}>Neuen Account erstellen</Link>
              </LoginForm>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
});
export default Login;
