import Polyglot from "node-polyglot";
import numbro from "numbro";
import { Socket } from "socket.io-client";
import initRedux, { RootState } from "../redux";
import rootSaga from "../redux/rootSaga";
import language from "../translations/de";
import axios, { AxiosInstance } from "axios";
import "./index.scss";
import { SagaMiddleware } from "redux-saga";
import { Store } from "redux";
import { History } from "history";
import { ReturnError } from "../redux/app/saga";
import * as serviceWorkerRegistration from "../serviceWorkerRegistration";
import { UPDATE } from "../redux/app/types";

export default class App {
  public io?: Socket;
  public readonly saga: SagaMiddleware;
  public readonly store: Store<RootState>;
  public readonly api: AxiosInstance;
  public readonly history: History;
  public translation = new Polyglot({
    locale: "de",
  });
  public readonly t = this.translation.t.bind(this.translation);

  constructor() {
    numbro.setLanguage("de-DE");
    const redux = initRedux();
    this.store = redux.store;
    this.history = redux.history;
    this.saga = redux.sagaMiddleware;
    this.api = axios.create({
      baseURL: process.env.SERVER_URL,
    });
    this.saga.run(rootSaga, this);

    this.translation.extend(language);

    let currentSettings: RootState["settings"] | undefined;
    this.store.subscribe(() => {
      const state = this.store.getState();
      if (state.settings !== currentSettings) {
        localStorage.setItem("settings", JSON.stringify(state.settings));
        currentSettings = state.settings;
      }
    });

    serviceWorkerRegistration.register({
      onUpdate: (registration) => {
        this.store.dispatch({ type: UPDATE, registration });
      },
      // onSuccess: (registration) => {
      //   console.log("onSuccess", registration);
      // },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public call<T, A extends any[]>(call: string, ...args: A): Promise<T> {
    return new Promise((resolve, reject) => {
      if (!this.io) {
        return reject("Connection not established. Not logged in yet?");
      }
      return this.io.emit(call, ...args, (ret: unknown) => {
        if (typeof ret === "object" && ret && typeof (ret as ReturnError).ioError === "boolean") {
          reject((ret as ReturnError).ret);
        } else {
          resolve(ret as T);
        }
      });
    });
  }
}
