import { Link } from "react-router-dom";
import * as React from "react";
import { Logo } from "../../app/layout";
import { Button, Col, Container, Dropdown, Nav, Navbar } from "react-bootstrap";
import { useServerUrl } from "../../../hooks/app";
import { connect } from "react-redux";
import { selectCurrentCharacter } from "../../../redux/character/selectors";
import { RootState } from "../../../redux";
import { Character } from "@jas/rnd-game-types/lib/models/Character";
import { LinkContainer } from "react-router-bootstrap";
import { DoorClosed } from "react-bootstrap-icons";
import { IoDocument } from "@jas/rnd-game-types/lib";
import { parseISO } from "date-fns";

const mapStateToProps = (state: RootState) => ({
  char: selectCurrentCharacter(state),
});
const Portrait = connect(mapStateToProps)(({ char }: { char: IoDocument<Character> }) => (
  <Dropdown>
    <Dropdown.Toggle className="d-flex align-items-center text-white text-decoration-none" id="avatar">
      <img
        src={useServerUrl(`/images/game/c/${char.id}/portrait.png?t=${parseISO(char.lastModified).getTime()}`)}
        width={32}
        height={32}
        alt=""
        className="rounded-circle me-2"
      />
      <strong>{char.name}</strong>
    </Dropdown.Toggle>
    <Dropdown.Menu className="text-small shadow">
      <LinkContainer to="/account">
        <Dropdown.Item>Profil</Dropdown.Item>
      </LinkContainer>
      <LinkContainer to="settings">
        <Dropdown.Item>Einstellungen</Dropdown.Item>
      </LinkContainer>
      <Dropdown.Divider />
      <LinkContainer to="character/select">
        <Dropdown.Item>Ausloggen</Dropdown.Item>
      </LinkContainer>
    </Dropdown.Menu>
  </Dropdown>
));

export const InGameHeader = () => (
  <Navbar variant="dark" bg="dark" sticky="top" className="bg-header navbar-header p-0 shadow">
    <Col xl={2} md={3} className="sidebar-col">
      <Portrait />
    </Col>
    <Col className="d-flex align-content-center">
      <Link to="/" className="d-inline-block">
        <Logo />
      </Link>
      {/*<button className="navbar-toggler collapse" type="button" data-bs-toggle="collapse">
          <span className="navbar-toggler-icon" />
        </button>*/}
    </Col>
  </Navbar>
);

export const MenuHeader = () => (
  <Navbar variant="dark" bg="dark" sticky="top" expand="md" className="bg-header navbar-header p-0 mb-4">
    <Container>
      <LinkContainer to="/">
        <Navbar.Brand className="d-flex align-items-center">
          <Logo />
        </Navbar.Brand>
      </LinkContainer>
      <Navbar.Toggle aria-controls="menu-navbar-nav" />
      <Navbar.Collapse id="menu-navbar-nav">
        <Nav className="ms-auto">
          <LinkContainer to="/account">
            <Button>
              <span className="d-none d-sm-inline">Profil</span>
            </Button>
          </LinkContainer>
          <LinkContainer to="/logout">
            <Button>
              <DoorClosed className="bi" /> <span className="d-none d-sm-inline">Abmelden</span>
            </Button>
            {/*<Nav.Link>Abmelden</Nav.Link>*/}
          </LinkContainer>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
