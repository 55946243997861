import { Character, Classes } from "@jas/rnd-game-types/lib/models/Character";
import React, { useState } from "react";
import { connect } from "react-redux";
import { useServerUrl, useTranslate } from "../../../hooks/app";
import { RootState } from "../../../redux";
import { deleteCharacter, goToCreateCharacter, loginCharacter } from "../../../redux/account/actions";
import ConfirmDialog from "../../../components/bs/ConfirmDialog";
import { Button, Image, ListGroup } from "react-bootstrap";
import "./select.scss";
import { BoxArrowInRight, PersonPlus, Plus, Trash } from "react-bootstrap-icons";
import { IoDocument } from "@jas/rnd-game-types/lib";
import { parseISO } from "date-fns";

interface Props {
  characters: IoDocument<Character>[];
  createAllowed: boolean;
  login: typeof loginCharacter;
  create: typeof goToCreateCharacter;
  delete: typeof deleteCharacter;
}
const CharacterList = (props: Props) => {
  const t = useTranslate();
  const serverUrl = useServerUrl();
  const [deleteDialog, setDeleteDialog] = useState(undefined as IoDocument<Character> | undefined);
  return (
    <>
      <ConfirmDialog
        action={
          deleteDialog &&
          (async () => {
            await props.delete(deleteDialog);
            setDeleteDialog(undefined);
          })
        }
        onRequestClose={() => setDeleteDialog(undefined)}
        confirmLabel={t("character.delete.submit")}
        confirmIntent="danger"
        abortLabel={t("character.delete.abort")}
        title={t("character.delete.title")}
      >
        {t("character.delete.confirm", { name: deleteDialog?.name })}
      </ConfirmDialog>
      <ListGroup as="ul">
        {props.characters.map((char, i) => (
          <ListGroup.Item
            as="li"
            key={i}
            action
            className="d-flex justify-content-between align-items-center char-select-item"
            onClick={() => props.login(char)}
          >
            <Image
              alt={char.name}
              className={"rounded-circle"}
              style={{ width: "67px", height: "67px" }}
              src={`${serverUrl}/images/game/c/${char.id}/portrait.png?t=${parseISO(char.lastModified).getTime()}`}
            />
            <div className="ms-3 me-auto">
              <strong className="d-block mb-1">{char.name}</strong>
              <small className="d-block">
                {t("character.level")} {char.level}
                <br />
                {t("classes." + Classes[char.class])}
              </small>
            </div>
            <Button
              className={"delete me-2"}
              title={t("character.delete.link")}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                setDeleteDialog(char);
              }}
            >
              <Trash className={"bi"} />
            </Button>
            <Button onClick={() => props.login(char)}>
              <BoxArrowInRight className={"bi"} /> <span className="d-none d-lg-inline">{t("character.select")}</span>
            </Button>
          </ListGroup.Item>
        ))}
        {props.createAllowed && (
          <ListGroup.Item as="li" action onClick={props.create} className="d-flex justify-content-between align-items-center char-select-item">
            <Plus />
            <div className="ms-2 me-auto">
              <i>{t("character.create.name")}</i>
            </div>
            <Button>
              <PersonPlus className={"bi"} /> {t("character.create.submit")}
            </Button>
          </ListGroup.Item>
        )}
      </ListGroup>
    </>
  );
};

export default connect(
  (state: RootState) => ({
    characters: state.account.characterList,
    createAllowed: state.account.createAllowed,
  }),
  {
    login: loginCharacter,
    create: goToCreateCharacter,
    delete: deleteCharacter,
  }
)(CharacterList);
