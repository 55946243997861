import { ChatMessage } from "@jas/rnd-game-types/lib/models/Chat";
import { eventChannel, SagaIterator } from "redux-saga";
import { call, put, take, takeEvery } from "redux-saga/effects";
import App from "../../app";
import { newChatMsg, send } from "./actions";
import { CHAT_RESTORE_HISTORY, SEND_MSG } from "./types";
import { Socket } from "socket.io-client";
import { CONNECTED } from "../app/types";

export default function* (app: App): SagaIterator {
  yield takeEvery(CONNECTED, function* ({ io }: { type: string; io: Socket }) {
    const chatQueue = eventChannel((emitter) => {
      const handler = (msg: ChatMessage) => emitter(msg);
      io.on("message", handler);
      return () => io.off("message", handler);
    });

    yield call(function* (): SagaIterator {
      const msgs = yield call([app, app.call], "query/chat_messages");
      yield put({ type: CHAT_RESTORE_HISTORY, payload: msgs });
    });

    yield takeEvery(SEND_MSG, ({ payload }: ReturnType<typeof send>) => io.send(payload));
    while (true) {
      yield put(newChatMsg(yield take(chatQueue)));
    }
  });
}
