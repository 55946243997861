import React from "react";
import Hotkeys from "react-hot-keys";

export const NoInputFocus = (disabled?: boolean) =>
  !disabled && (!document.activeElement || ["INPUT", "TEXTAREA", "SELECT"].includes(document.activeElement.nodeName.toUpperCase()));

type Disabled = () => boolean;
type Action = (event?: KeyboardEvent, handler?: { key: string }) => void;

export interface ShortCutProps {
  keys?: string | string[];
  alias?: string;
  action: Action;
  disabled: Disabled;
  children?: React.ReactNode;
}

const isDisabled = (val: Disabled): boolean => {
  if (typeof val === "function") {
    return val();
  }
  return val;
};

const wrapAction = (action: Action, disabled: Disabled) => (handler?: string, event?: KeyboardEvent) => {
  isDisabled(disabled) || action(event, handler ? { key: handler } : undefined);
};

const KEY_MAP: { [key: string]: string | string[] } = {
  toggleChat: "c",
};

const ShortCut = ({ keys, alias, action, disabled, children }: ShortCutProps) => {
  if (!keys) {
    if (!alias) {
      throw new Error("Either need alias or keys");
    }
    keys = KEY_MAP[alias];
  }
  if (!Array.isArray(keys)) {
    keys = [keys];
  }

  return (
    <Hotkeys keyName={keys.join(",")} onKeyDown={wrapAction(action, disabled)}>
      {children}
    </Hotkeys>
  );
};
ShortCut.defaultProps = {
  disabled: NoInputFocus,
};

export default ShortCut;
