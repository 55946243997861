import { combineReducers } from "redux";
import app from "./app/reducers";
import account from "./account/reducers";
import character from "./character/reducers";
import chat from "./chat/reducers";
import notifications from "./notifications/reducers";
import settings from "./settings/reducers";
import { routerReducer } from "./history";

export default combineReducers({
  router: routerReducer,
  app,
  account,
  character,
  chat,
  settings,
  notifications,
});
