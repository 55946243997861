import * as React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router";
import { LoginForm } from "./login";

export const Activate = () => {
  const { token } = useParams();
  return (
    <Row className={"justify-content-center mt-3"}>
      <Col md={8} lg={6}>
        <Card>
          <Card.Body>
            <Card.Title>Registrierung</Card.Title>
            {token === "success" ? (
              <div>
                <p>Aktivierung erfolgreich.</p>
                <p>Sie können sich nun einloggen.</p>
                <LoginForm />
              </div>
            ) : (
              <div>
                <p>Aktivierungs-Link ungültig.</p>
                <p>Der Link ist nur 24 Stunden gültig. Bitte probiere es erneut dich zu registrieren, und verwende den Link aus der E-Mail zeitnah.</p>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};
