import React from "react";
import { Classes } from "@jas/rnd-game-types/lib/models/Character";
import { useTranslate } from "../../../hooks/app";
import { colorToInt, enumObject, intToColor } from "@jas/util/lib";
import { RGBColor, SketchPicker } from "react-color";
import { createCharacter } from "../../../redux/account/actions";
import { Button, Form, ButtonProps, OverlayTrigger, Card, Popover, Row, Col } from "react-bootstrap";
import { Form as FormFields } from "react-bootstrap-formik";
import { useField, Formik, useFormikContext, FormikHelpers } from "formik";
import { connect } from "react-redux";
import ImagePreview from "./preview";

const ColorValue = ({ value }: { value: RGBColor }) => (
  <div
    style={{
      width: "100%",
      height: "calc(100% - 0.5rem)",
      minHeight: 24,
      borderRadius: "2px",
      background: `rgb(${value.r}, ${value.g}, ${value.b})`,
    }}
  />
);

const ColorInput = React.forwardRef((props: ButtonProps, ref: React.Ref<HTMLButtonElement>) => {
  return <button className="form-control" type="button" {...props} ref={ref} />;
});

const RenderColorInput = (props: { name: string }) => {
  const [input, , helpers] = useField(props);
  return (
    <OverlayTrigger
      trigger="click"
      rootClose
      overlay={
        <Popover>
          <Popover.Body>
            <SketchPicker disableAlpha color={input.value} onChange={(val) => helpers.setValue(val.rgb)} />
          </Popover.Body>
        </Popover>
      }
    >
      <ColorInput>
        <ColorValue value={input.value} />
      </ColorInput>
    </OverlayTrigger>
  );
};

interface FormData {
  name: string;
  charClass: Classes;
  hairStyle: 1 | 2 | 3;
  hairColor: RGBColor;
}

interface Props {
  createCharacter: typeof createCharacter;
}

const ConnectedImagePreview = () => {
  const {
    values: { charClass, hairStyle, hairColor },
  } = useFormikContext<FormData>();
  if (!charClass) {
    return null;
  }
  return <ImagePreview characterClass={charClass} hairStyle={hairStyle} hairColor={hairColor} />;
};

const CharacterCreate = ({ createCharacter }: Props) => {
  const t = useTranslate();

  const hairStyles = {
    // simple: 0,
    long: 1,
    short: 2,
    extended: 3,
  };

  return (
    <>
      <Formik
        initialValues={{
          name: "",
          charClass: Math.pow(2, Math.floor(Math.random() * 5)) as Classes,
          hairStyle: (Math.floor(Math.random() * 3) + 1) as 1 | 2 | 3,
          hairColor: intToColor(Math.random() * 0x1000000),
        }}
        onSubmit={async (values: FormData, actions: FormikHelpers<FormData>) => {
          const { charClass, hairColor, ...data } = values;
          const result = await createCharacter({
            ...data,
            hairColor: colorToInt(hairColor),
            class: charClass,
          }).io;
          console.log(result);
          if (!result.success) {
            if (result.name) {
              actions.setFieldError("name", t(result.name));
            }
          }
        }}
      >
        {({ handleSubmit, isSubmitting }) => (
          <Row>
            <Col className="mb-3">
              <Card>
                <Card.Body>
                  <Card.Title>{t("character.create.title")}</Card.Title>
                  <Form onSubmit={handleSubmit}>
                    <Form.Group>
                      <Form.Label>{t("character.name")}</Form.Label>
                      <FormFields.Input name="name" required />
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t("character.class")}</Form.Label>
                      <FormFields.Select name="charClass" required>
                        {Object.entries(enumObject(Classes)).map(([className, classType]) => (
                          <option key={classType} value={classType.toString()}>
                            {t("classes." + className)}
                          </option>
                        ))}
                      </FormFields.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t("character.hair_style")}</Form.Label>
                      <Form.Select name="hairStyle" required>
                        {Object.entries(hairStyles).map(([name, value]) => (
                          <option key={value} value={value.toString()}>
                            {t("hairstyle." + name)}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                    <Form.Group>
                      <Form.Label>{t("character.hair_color")}</Form.Label>
                      <RenderColorInput name="hairColor" />
                    </Form.Group>
                    <Button type="submit" className="mt-3" disabled={isSubmitting}>
                      {t("character.create.submit")}
                    </Button>
                  </Form>
                </Card.Body>
              </Card>
            </Col>
            <Col md={"auto"}>
              <Card>
                <Card.Body>
                  <ConnectedImagePreview />
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
      </Formik>
    </>
  );
};

export default connect(undefined, { createCharacter })(CharacterCreate);
