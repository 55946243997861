import { IoDocument } from "@jas/rnd-game-types/lib";
import { ChatMessage } from "@jas/rnd-game-types/lib/models/Chat";
import { AnyAction, combineReducers } from "redux";
import { CHAT_MSG, CHAT_RESTORE_HISTORY, TOGGLE_CHAT } from "./types";

const messages = (state: IoDocument<ChatMessage>[] = [], action: AnyAction): IoDocument<ChatMessage>[] => {
  switch (action.type) {
    case CHAT_RESTORE_HISTORY: {
      return (action.payload || []).concat(state);
    }
    case CHAT_MSG: {
      return state.concat([action.payload]);
    }
  }
  return state;
};
const display = (state = { minimized: true }, action: AnyAction) => {
  switch (action.type) {
    case TOGGLE_CHAT: {
      return { ...state, minimized: action.payload !== undefined ? (action.payload as boolean) : !state.minimized };
    }
  }
  return state;
};
const newMessages = (state = false, action: AnyAction): boolean => {
  switch (action.type) {
    case CHAT_MSG: {
      return true;
    }
    case TOGGLE_CHAT: {
      return false;
    }
  }
  return state;
};

export default combineReducers({
  messages,
  display,
  newMessages,
});
