import { Card, Col, Form, Row } from "react-bootstrap";
import * as React from "react";
import { useTranslate } from "../../hooks/app";
import { ChangeEvent, useCallback } from "react";
import { disableFullScreen, enableFullScreen, isFullScreen } from "../../app/pwa/helper";

const FullscreenSetting = () => {
  const t = useTranslate();
  const forceUpdate = React.useReducer(() => ({}), {})[1] as () => void;

  const toggleFullscreen = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.checked) {
        enableFullScreen();
      } else {
        disableFullScreen();
      }
      setTimeout(forceUpdate, 250);
    },
    [forceUpdate]
  );

  return (
    <>
      <Form.Check type="switch" id="fullscreen" label={t("settings.fullscreen")} onChange={toggleFullscreen} checked={isFullScreen()} />
    </>
  );
};

const Settings = () => {
  return (
    <>
      <Row className={" mt-3"}>
        <Col md={8} lg={6}>
          <Card>
            <Card.Body>
              <Card.Title>Ansicht</Card.Title>
              <FullscreenSetting />
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Settings;
