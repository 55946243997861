import { InventoryItemInstance, InventoryType } from "@jas/rnd-game-types/lib/models/Inventory";
import { ItemSlot } from "@jas/rnd-game-types/lib/models/Item";
import { EQUIP_ITEM, MOVE_ITEM, UNDRESS_ITEM } from "./types";

export const moveItem = (
  item: InventoryItemInstance,
  targetSlot: number,
  sourceInventory = InventoryType.CHARACTER_INVENTORY,
  targetInventory = InventoryType.CHARACTER_INVENTORY
) => ({
  type: MOVE_ITEM,
  payload: {
    targetSlot,
    targetInventory,
    sourceItemId: item.id,
    sourceInventory,
  },
  io: true,
});
export const equipItem = (item: InventoryItemInstance, slot?: ItemSlot) => ({
  type: EQUIP_ITEM,
  payload: { targetSlot: slot, sourceItemId: item.id },
  io: true,
});
export const undressItem = (item: InventoryItemInstance, targetSlot: ItemSlot) => ({
  type: UNDRESS_ITEM,
  payload: { targetSlot, sourceItemId: item.id },
  io: true,
});
