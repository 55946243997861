import App from "../../app";
import { cancel, fork, put, take, takeEvery } from "redux-saga/effects";
import { LOGIN_SUCCESS, LOGOUT } from "../account/types";
import { AnyAction } from "redux";
import { addNotification } from "../notifications/actions";
import { NotificationType } from "../notifications/types";
import { DISCONNECT, EXECUTE_UPDATE, UPDATE } from "./types";
import { io } from "socket.io-client";
import IoEvents from "@jas/rnd-game-types/lib/io-events";
import { SagaIterator } from "redux-saga";
import { connected, connecting, disconnected, reconnect } from "./actions";

export interface ReturnError {
  ioError?: boolean | null;
  ret?: string | Error;
}
function* ioSaga(this: App): SagaIterator {
  while (true) {
    const {
      payload: { token },
    } = yield take(LOGIN_SUCCESS);
    yield put(connecting());
    const ioOpts = {
      query: {
        sess: sessionStorage.getItem("session-id") || "",
        token,
      },
    };
    this.io = process.env.SERVER_URL ? io(process.env.SERVER_URL, ioOpts) : io(ioOpts);
    this.io.once("set-session-id", (sid: string) => {
      sessionStorage.setItem("session-id", sid);
    });
    this.io.on(IoEvents.SAGA, (action: AnyAction) => {
      return this.store.dispatch(action);
    });
    this.io.on("clients.error", (error: { ret: string }) => {
      return this.store.dispatch(addNotification(error.ret, NotificationType.ERROR));
    });
    this.io.on("disconnect", (reason: string) => {
      if (reason === "") {
        this.store.dispatch(disconnected());
      }
    });
    this.io.io.on("reconnect_attempt", () => this.store.dispatch(reconnect(true)));
    this.io.io.on("reconnect", () => this.store.dispatch(reconnect(false)));

    const task = yield fork(
      function* (this: App) {
        yield takeEvery(
          (action: AnyAction) => action.io,
          (action: AnyAction) => {
            if (!this.io) {
              throw new Error("Connection not established. Not logged in yet?");
            }
            if (typeof action.io.resolve === "function") {
              return this.io.emit(action.type, action.payload, (ret: unknown) => {
                if (typeof ret === "object" && ret && typeof (ret as ReturnError).ioError === "boolean") {
                  action.io.reject((ret as ReturnError).ret);
                } else {
                  action.io.resolve(ret);
                }
              });
            } else {
              this.io.emit(action.type, action.payload);
            }
          }
        );
      }.bind(this)
    );
    yield put(connected(this.io));
    yield take([LOGOUT, DISCONNECT]);
    yield cancel(task);
    if (this.io) {
      this.io.close();
      yield put(disconnected());
    }
  }
}

function* pwaSaga(): SagaIterator {
  const { registration } = (yield take(UPDATE)) as { type: typeof UPDATE; registration: ServiceWorkerRegistration };

  yield take(EXECUTE_UPDATE);

  if (process.env.NODE_ENV === "production" && "serviceWorker" in navigator) {
    let refreshing = false;
    navigator.serviceWorker.addEventListener("controllerchange", function () {
      if (refreshing) return;
      refreshing = true;
      window.location.reload();
    });
  }

  registration.waiting?.postMessage({ type: "SKIP_WAITING" });
}

export default function* (app: App) {
  yield fork(ioSaga.bind(app));
  yield fork(pwaSaga);
}
