import { Item } from "./Item";

export enum ItemColor {
  NONE = 0,
  RED = 114, // r
  GREEN = 103, // g
  BLUE = 98, // b
  YELLOW = 121, // y
  VIOLET = 118, // v
  PINK = 112, // p
  TURQUOISE = 116, // t
  ORANGE = 111, // o
  BLACK = 107, // k
  WHITE = 119, // w
  GOLD = 100, // d
  SILVER = 115, // s
}

export interface ItemData {
  color?: ItemColor;
}

export interface ItemInstance {
  item: Item;
  itemData: ItemData;
}
