import * as React from "react";
import { Container } from "react-bootstrap";
import { Footer, Header } from "../app/layout";
import Notifications from "../app/notifications";

export const MainContainer = ({ children }: { children: React.ReactNode }) => (
  <>
    <Header />
    <Container>{children}</Container>
    <Footer />
    <Notifications />
  </>
);
