import { Account } from "./Account";
import { ItemSlot } from "./Item";
import { ItemInstance } from "./ItemInstance";

export enum Classes {
  SHAPESHIFTER = 1, // 0000 0001
  GHOST = 2, // 0000 0010
  WARRIOR = 4, // 0000 0100
  FORESTFAIRY = 8, // 0000 1000
  MAGE = 16, // 0001 0000
}

export interface EquipmentSlotInstance extends ItemInstance {
  slot: ItemSlot;
}

export interface Character {
  id: string;
  account: Account;
  name: string;
  level: number;
  class: Classes;
  hairStyle: number;
  hairColor: number;
  lastAction: Date;
  credits: number;
  exp: number;
  lastModified: Date;
  equipment: EquipmentSlotInstance[];
}
