import * as React from "react";
import { GameContentLeft, GameContentMain, GameContentRight } from "../layout/content";
import Equipment from "./equipment";
import InventoryBag from "./inventory";
import { DndProvider } from "react-dnd";
import CustomDragLayer from "./dnd/layer";
import { TouchBackend } from "react-dnd-touch-backend";

const Inventory = () => {
  return (
    <GameContentMain>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <CustomDragLayer />
        <GameContentLeft>
          <Equipment />
        </GameContentLeft>
        <GameContentRight>
          <InventoryBag useCanEquip={true} />
        </GameContentRight>
      </DndProvider>
    </GameContentMain>
  );
};

export default Inventory;
