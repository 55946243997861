import { Character, Classes } from "@jas/rnd-game-types/lib/models/Character";
import * as React from "react";
import { useSelector } from "react-redux";
import { useServerUrl, useTranslate } from "../../../hooks/app";
import { RootState } from "../../../redux";
import { GameContentLeft, GameContentMain, GameContentRight, Pergament, Title } from "../layout/content";
import { selectCurrentCharacter } from "../../../redux/character/selectors";
import { IoDocument } from "@jas/rnd-game-types/lib";
import { parseISO } from "date-fns";
import { ProgressBar } from "react-bootstrap";
import { format } from "../inventory/helper";

const CharacterDisplay = ({ char, children }: { char: IoDocument<Character>; children: React.ReactNode }) => {
  const t = useTranslate();
  return (
    <GameContentMain>
      <GameContentLeft>
        <div className="character display">
          <img
            className="d-block mx-auto img-fluid"
            src={useServerUrl(`/images/game/c/${char.id}/display.png?t=${parseISO(char.lastModified).getTime()}`)}
            alt=""
          />
        </div>
      </GameContentLeft>
      <GameContentRight>
        <Pergament className="mx-auto">
          <Title>{char.name}</Title>
          <div style={{ textAlign: "center" }}>
            {t("character.level")} {char.level} {t("classes." + Classes[char.class])}
          </div>
          {children}
          {/*<div className="character actions ajax">
              <div className="market">
                <a href="/game/market/catalog?player=12" className=" sbutton">
                  <span className="sbuttoni">
                    <span className="sbuttona">Marktplatz</span>
                  </span>
                </a>
              </div>
            </div>*/}
        </Pergament>
      </GameContentRight>
    </GameContentMain>
  );
};

const CurrentCharacter = () => {
  const t = useTranslate();
  const char = useSelector(selectCurrentCharacter);
  const characterClass = useSelector((state: RootState) => state.character.characterClass);

  return (
    <CharacterDisplay char={char}>
      <section className={"mt-3 character-info"}>
        <legend>{t("character.info")}</legend>
        <label>{t("character.exp")}</label>
        <ProgressBar
          now={char.exp}
          max={characterClass?.maxExp}
          variant={"info"}
          className={"exp"}
          label={`${format.stat(char.exp)} / ${format.stat(characterClass?.maxExp || 0)}`}
        />

        <label>{t("character.credits")}</label>
        <ProgressBar
          now={100}
          max={100}
          className="credits"
          label={
            <span>
              {format.stat(char.credits)} <span className="currency credits">{t("character.credits")}</span>
            </span>
          }
        />
      </section>
    </CharacterDisplay>
  );
};
export default CurrentCharacter;
