import * as React from "react";
import classed from "../../util/classed";
import Scrollbars from "react-custom-scrollbars-2";
import classNames from "classnames";

const VerticalThumb = classed.div("vertical-thumb");

interface Props {
  className?: string;
  children: React.ReactNode;
}

class ScrollView extends React.Component<Props> {
  public state = {
    moreUp: false,
    moreDown: false,
    autoScroll: true,
  };
  private el = React.createRef<Scrollbars>();

  public touch() {
    if (this.state.autoScroll && this.el.current) {
      this.el.current.scrollToBottom();
    }
  }

  public onScroll = ({ top }: { top: number }) => {
    if (this.el.current) {
      const enableAutoScroll = top === 1;
      if (enableAutoScroll !== this.state.autoScroll) {
        this.setState({ autoScroll: enableAutoScroll });
      }
      this.checkScroll();
      // const h = this.el.current.getScrollHeight();
      // const ch = this.el.current.getClientHeight();
      // console.log("onScroll", top, h, ch);
    }
  };

  checkScroll = () => {
    if (this.el.current) {
      const newState = { moreUp: false, moreDown: false };
      if (this.el.current.getScrollTop() > 0) {
        newState.moreUp = true;
      }
      if (this.el.current.getScrollTop() < this.el.current.getScrollHeight() - this.el.current.getClientHeight()) {
        newState.moreDown = true;
      }
      this.setState(newState);
    }
  };

  public render() {
    const { children, className } = this.props;
    return (
      <Scrollbars
        className={classNames(className, {
          "more-up": this.state.moreUp,
          "more-down": this.state.moreDown,
        })}
        ref={this.el}
        renderThumbVertical={this.renderThumbVertical}
        onScrollFrame={this.onScroll}
        // autoHide={this.state.autoScroll}
        // autoHideTimeout={1000}
        // autoHideDuration={200}
      >
        {children}
      </Scrollbars>
    );
  }
  private renderThumbVertical: React.FunctionComponent = () => <VerticalThumb />;
}

export default ScrollView;
