import * as React from "react";
import { useTranslate } from "../../hooks/app";
import { BarChart, Border, Boxes, CashStack, ChatLeft, ChatLeftDots, Envelope, Handbag, ListCheck, Map, PersonCircle, Shop } from "react-bootstrap-icons";
import { MenuButton, MenuLink, TheMenu } from "./layout/menu";
import classNames from "classnames";
import { connect } from "react-redux";
import { toggleChat } from "../../redux/chat/actions";
import ShortCut from "../../components/ShortCut";
import { RootState } from "../../redux";

const GameMenu = ({ className }: { className?: string }) => {
  const t = useTranslate();
  return (
    <TheMenu className={classNames("game-menu", className)}>
      <MenuButton to="/game/character/display" icon={<PersonCircle />}>
        {t("game.character")}
      </MenuButton>
      <MenuButton to="/game/inventory" icon={<Handbag />}>
        {t("game.inventory")}
      </MenuButton>
      <MenuButton to="/game/wardrobe" icon={<Boxes />}>
        {t("game.wardrobe")}
      </MenuButton>
      <MenuButton to="/game/quests" icon={<ListCheck />} disabled>
        {t("game.quests")}
      </MenuButton>
      <MenuButton to="/game/shop" icon={<Shop />} disabled>
        {t("game.shop")}
      </MenuButton>
      <MenuButton to="/game/dungeon" icon={<Border />} disabled>
        {t("game.dungeon")}
      </MenuButton>
      <MenuButton to="/game/market" icon={<CashStack />} disabled>
        {t("game.market")}
      </MenuButton>
      <MenuButton to="/game/mail" icon={<Envelope />} disabled>
        {t("game.mail")}
      </MenuButton>
      <MenuButton to="/game/map" icon={<Map />} disabled>
        {t("game.map")}
      </MenuButton>
      <MenuButton to="/game/character/list" icon={<BarChart />} disabled>
        {t("game.halloffame")}
      </MenuButton>
      {/*<MenuButton to="/game/character/list">{t('game.menu.test')}</MenuButton>*/}
      {/*<MenuButton href="https://wiki.risunodensetsu.de" target="_blank">{t('game.menu.help')}</MenuButton>*/}
    </TheMenu>
  );
};

export const UiMenu = connect((state: RootState) => ({ newMessages: state.chat.newMessages }), { toggleChat })(
  ({ newMessages, toggleChat, ...props }: React.HTMLAttributes<HTMLDivElement> & { toggleChat: () => void; newMessages: boolean }) => (
    <div {...props}>
      <MenuLink icon={newMessages ? <ChatLeftDots /> : <ChatLeft />} onClick={toggleChat}>
        <ShortCut alias="toggleChat" action={toggleChat} />
        Chat
      </MenuLink>
    </div>
  )
);

export default GameMenu;
