import * as React from "react";
import { DndProvider } from "react-dnd";
import { GameContentLeft, GameContentMain, GameContentRight } from "../../layout/content";
import CustomDragLayer from "../dnd/layer";
import InventoryBag from "../inventory";
import Cabinet from "./cabinet";
import { TouchBackend } from "react-dnd-touch-backend";

const Wardrobe = () => {
  return (
    <GameContentMain>
      <DndProvider backend={TouchBackend} options={{ enableMouseEvents: true }}>
        <CustomDragLayer />
        <GameContentLeft>
          <InventoryBag />
        </GameContentLeft>
        <GameContentRight>
          <Cabinet />
        </GameContentRight>
      </DndProvider>
    </GameContentMain>
  );
};

export default Wardrobe;
