import * as React from "react";
import { connect, Provider, useSelector } from "react-redux";
import AppInstance from "../../app";
import { RootState } from "../../redux";
import { MainContainer } from "../main";
import { HistoryRouter } from "redux-first-history/rr6";
import { Navigate, Outlet, Route, Routes } from "react-router";
import GameRoutes from "../game";
import Agb from "../main/agb";
import PasswordReset from "../main/password-reset";
import Login from "../main/login";
import Logout from "../main/logout";
import { FileNotFound } from "../main/404";
import { Activate } from "../main/activate";
import Register from "../main/register";
import { ArrowRepeat, XLg } from "react-bootstrap-icons";
import Account from "../main/account";

const app = new AppInstance();
if (process.env.NODE_ENV === "development") {
  window.app = app;
}

export const AppContext = React.createContext(app);

const PublicContentContainer = () => (
  <MainContainer>
    <Outlet />
  </MainContainer>
);

const ConnectedAppRoutes = connect((state: RootState) => ({
  isLoggedIn: state.account.isLoggedIn,
  charLoggedIn: !!state.character.currentCharacter,
}))(({ isLoggedIn, charLoggedIn }: { isLoggedIn: boolean; charLoggedIn: boolean }) => {
  return (
    <>
      <Routes>
        <Route path="game/*" element={isLoggedIn ? <GameRoutes /> : <Navigate to={"/login"} />} />
        <Route path="/" element={<PublicContentContainer />}>
          <Route path="agb" element={<Agb />} />
          <Route path="account" element={<Account />} />
          <Route path="logout" element={<Logout />} />
          <Route path="register/confirm/:token" element={<Activate />} />
          <Route path="register/*" element={<Register />} />
          <Route path="password-reset/*" element={<PasswordReset />} />
          <Route path="login" element={<Login />} />
          {isLoggedIn ? (
            <Route index element={charLoggedIn ? <Navigate to="/game/character/display" /> : <Navigate to="/game/character/select" />} />
          ) : (
            <Route index element={<Navigate to="/login" />} />
          )}
          <Route path="*" element={<FileNotFound />} />
        </Route>
      </Routes>
    </>
  );
});

const Connecting = () => (
  <div className="app-connecting d-flex justify-content-center align-items-center align-self-stretch flex-grow-1">
    <div className="text-dark">
      <div className="text-center">
        <ArrowRepeat className="bi fa-spin" size="8rem" />
      </div>
      <div className="mt-3 text-center">Verbindung wird hergestellt...</div>
    </div>
  </div>
);
const ReConnecting = () => (
  <div className="app-connecting d-flex justify-content-center align-items-center align-self-stretch flex-grow-1">
    <div className="text-dark">
      <div className="text-center">
        <XLg className="bi" size="8rem" />
      </div>
      <div className="mt-1 text-center">Verbindung unterbrochen.</div>
      <div className="mt-3 text-center">
        <ArrowRepeat className={"bi fa-spin"} /> Verbindung wird wiederhergestellt...
      </div>
    </div>
  </div>
);

const Root = () => {
  const connecting = useSelector((state: RootState) => state.app.booting);
  const reconnecting = useSelector((state: RootState) => state.app.reconnecting);
  if (connecting) {
    return <Connecting />;
  }
  if (reconnecting) {
    return <ReConnecting />;
  }
  return <ConnectedAppRoutes />;
};

const App = () => {
  return (
    <>
      <Provider store={app.store}>
        <HistoryRouter history={app.history}>
          <AppContext.Provider value={app}>
            <Root />
          </AppContext.Provider>
        </HistoryRouter>
      </Provider>
    </>
  );
};
export default App;
