declare global {
  interface ObjectConstructor {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    fromEntries(xs: [string | number | symbol, any][]): object;
  }
}

export type Enum<E> = Record<keyof E, number | string> & { [k: number]: string };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const fromEntries = (xs: [string | number | symbol, any][]) =>
  Object.fromEntries ? Object.fromEntries(xs) : xs.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

/**
 * Only works for number enums
 */
export function enumObject<T extends Enum<T>>(theEnum: T) {
  return fromEntries(Object.entries(theEnum).filter(([, value]) => typeof value === "number")) as { [index: string]: T[keyof T] };
}

/**
 * Only works for number enums
 */
export function iterableEnum<T>(t: T): { [key: string]: T[keyof T] } {
  const keys = Object.keys(t) as [keyof T];
  return keys.reduce(
    (agg, key) =>
      Object.defineProperty(agg, key, {
        value: t[key],
        enumerable: !isFinite(+key),
      }),
    {}
  );
}
