const doc = window.document as Document & {
  mozFullScreenElement?: HTMLElement;
  webkitFullscreenElement?: HTMLElement;
  msFullscreenElement?: HTMLElement;
  mozCancelFullScreen?: () => void;
  webkitExitFullscreen?: () => void;
  msExitFullscreen?: () => void;
};
const docEl = doc.documentElement as HTMLElement & {
  mozRequestFullScreen?: () => void;
  webkitRequestFullScreen?: () => void;
  msRequestFullscreen?: () => void;
};

const requestFullScreen = docEl.requestFullscreen || docEl.mozRequestFullScreen || docEl.webkitRequestFullScreen || docEl.msRequestFullscreen;
const cancelFullScreen = doc.exitFullscreen || doc.mozCancelFullScreen || doc.webkitExitFullscreen || doc.msExitFullscreen;
export const isFullScreen = () => Boolean(doc.fullscreenElement || doc.mozFullScreenElement || doc.webkitFullscreenElement || doc.msFullscreenElement);

export const enableFullScreen = () => requestFullScreen.call(docEl);
export const disableFullScreen = () => cancelFullScreen.call(doc);

export function toggleFullScreen() {
  if (!isFullScreen()) {
    enableFullScreen();
  } else {
    disableFullScreen();
  }
}
