import * as React from "react";
import classed from "../../../util/classed";
import { Col, Row } from "react-bootstrap";

export const GameContentLeft = (props: { children?: React.ReactNode }) => <Col {...props} className="game-content-left" />;
export const GameContentRight = (props: { children?: React.ReactNode }) => <Col {...props} className="game-content-right" />;
export const GameContentMain = (props: { children?: React.ReactNode }) => <Row {...props} className="game-content-main" />;

const PergamentContainer = classed.div("pergament-container");

export const Title = classed.h1("text-center");

export const Pergament = ({ children, className }: { children: React.ReactNode; className?: string }) => (
  <PergamentContainer className={className}>
    <div className="inner">{children}</div>
  </PergamentContainer>
);
