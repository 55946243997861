import { Character } from "./Character";
import { ItemInstance } from "./ItemInstance";

export enum InventoryType {
  CHARACTER_INVENTORY,
  WARDROBE,
}

export interface InventoryItemInstance extends ItemInstance {
  id: string;
  slot: number;
}

export interface Inventory {
  character: string | Character;
  type: InventoryType;
  readonly size: number;
  items: InventoryItemInstance[];
}
