import { Inventory } from "@jas/rnd-game-types/lib/models/Inventory";
import * as React from "react";
import { connect } from "react-redux";
import { RootState } from "../../../redux";
import * as actions from "../../../redux/character/actions";
import { InventoryItem } from "./item";
import { InventorySlot, Slot } from "./slot/inventory";
import classed from "../../../util/classed";

const InventoryContainer = classed.div("inventory-container");

interface BagProps {
  inventory?: Inventory;
  useCanEquip?: boolean;
  moveItem: typeof actions.moveItem;
  undressItem: typeof actions.undressItem;
  equipItem: typeof actions.equipItem;
}
const Placeholder = classed(Slot)("invisible");

class InventoryBag extends React.Component<BagProps> {
  public render() {
    const { inventory, useCanEquip, moveItem, undressItem, equipItem } = this.props;
    if (!inventory) {
      return null;
    }
    return (
      <InventoryContainer>
        <div className="inner">
          <Placeholder />
          {this.inventoryMap().map(({ item, slot }, i) => (
            <InventorySlot inventory={inventory.type} slot={slot} key={i} equipItem={equipItem} moveItem={moveItem} undressItem={undressItem}>
              {item ? <InventoryItem item={item} inventory={inventory} useItem={() => useCanEquip && equipItem(item)} /> : null}
            </InventorySlot>
          ))}
        </div>
      </InventoryContainer>
    );
  }
  protected inventoryMap() {
    const { inventory } = this.props;
    const arr = [];
    if (inventory) {
      for (let i = 0; i < inventory.size; i++) {
        arr.push({
          slot: i,
          item: inventory.items.find((s) => s.slot === i),
        });
      }
    }
    return arr;
  }
}

export default connect(
  (state: RootState) => ({
    inventory: state.character.inventory || undefined,
  }),
  {
    equipItem: actions.equipItem,
    moveItem: actions.moveItem,
    undressItem: actions.undressItem,
  }
)(InventoryBag);
