import { fork } from "redux-saga/effects";
import App from "../app";
import appSaga from "./app/saga";
import accountSaga from "./account/saga";
import characterSaga from "./character/saga";
import chatSaga from "./chat/saga";

export default function* (app: App) {
  yield fork(appSaga, app);
  yield fork(accountSaga, app);
  yield fork(characterSaga, app);
  yield fork(chatSaga, app);
}
