enum IoEvents {
  SAGA = "_",

  // Account
  LOGIN = "app/account/LOGIN",
  LOGOUT = "app/account/LOGOUT",
  LOGIN_ERROR = "app/account/LOGIN_ERROR",
  LOGIN_SUCCESS = "app/account/LOGIN_SUCCESS",
  SELECT_CHARACTER = "app/account/SELECT_CHARACTER",
  CREATE_CHARACTER = "app/account/CREATE_CHARACTER",
  DELETE_CHARACTER = "app/account/DELETE_CHARACTER",
  RESET_PASSWORD = "app/account/RESET_PASSWORD",
  PASSPORT_LOGIN = "app/account/PASSPORT_LOGIN",

  // region Server-Events to Client
  CHAR_LOGGEDIN = "app/character/LOGGED_IN",
  CHAR_UPDATE = "app/character/UPDATE",
  CHARACTER_LIST = "app/character/LIST",
  INVENTORY_UPDATE = "app/character/INVENTORY_UPDATE",
  FETCH_WARDROBE = "app/character/FETCH_WARDROBE",

  // endregion

  // region Client-Events to Server

  // Inventory
  EQUIP_ITEM = "app/character/EQUIP_ITEM",
  UNDRESS_ITEM = "app/character/UNDRESS_ITEM",
  MOVE_ITEM = "app/character/inventory/MOVE_ITEM",

  // endregion
}
export default IoEvents;
