import React from "react";
import { useTranslate } from "../../hooks/app";
import { Button, Modal } from "react-bootstrap";
import { ButtonVariant } from "react-bootstrap/types";

interface MyDialogProps {
  action: (() => void) | undefined;
  onRequestClose: () => void;
  title?: React.ReactNode;
  confirmIntent?: ButtonVariant;
  confirmLabel?: React.ReactNode;
  abortLabel?: React.ReactNode;
  children: React.ReactNode;
}

const ConfirmDialog = (props: MyDialogProps) => {
  const t = useTranslate();
  const {
    action,
    onRequestClose: abort,
    title,
    confirmLabel = t("ui.confirm.submit"),
    abortLabel,
    confirmIntent = "primary",
    children = t("ui.confirm.text"),
  } = props;
  return (
    <Modal show={Boolean(action)} onHide={abort}>
      <Modal.Header closeButton>{title && <Modal.Title>{title}</Modal.Title>}</Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        {abortLabel && <Button onClick={abort}>{abortLabel}</Button>}
        <Button variant={confirmIntent} onClick={action}>
          {confirmLabel}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmDialog;
